import { makeAutoObservable } from 'mobx';

export class DeparturesStore {
  api;
  root;
  data;
  timeTable;
  cities;
  ships;
  loading;

  constructor(root, api) {
    this.root = root;
    this.data = [];
    this.api = api;
    this.headers = [
      { title: 'Теплоход', note: 'и трансфер (если есть)' },
      { title: 'Город отправления', note: 'отправление трансфера' },
      { title: 'Отправление', note: 'по Москве' },
      { title: 'Порт', note: 'и причал, трансфер' },
      { title: 'id' },
    ];
    this.loading = false;
    makeAutoObservable(this);
  }

  filterByCityAndShip(cities, ships) {
    const { timetable } = this.data || {};
    const isNoFilters = cities.length < 1 && ships.length < 1;

    if (isNoFilters) {
      // Сброс на дефолт, если вдруг мы фильтруем не один раз
      this.timeTable = timetable;
      return;
    }

    const isFilterByCity = cities.length > 0;
    const isFilterByShip = ships.length > 0;

    /* TODO: можно улучшить */
    this.timeTable = timetable.filter(i =>
      isFilterByCity && isFilterByShip
        ? cities.includes(i.route_start) && ships.includes(i.t_title)
        : cities.includes(i.route_start) || ships.includes(i.t_title),
    );
  }

  *fetchByDate(date) {
    // TODO: Есть смысл сократить кол-во полей
    try {
      this.loading = true;
      const { data: payload } = yield this.api.fetchByDate(date);
      this.data = payload;
      const { timetable, cities, ships } = this.data;
      this.timeTable = timetable;
      this.cities = cities;
      this.ships = ships;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  // Массив массивов с определенными полями для таблицы
  get tableItems() {
    const fieldRatio = [
      't_title',
      'route_start',
      'time_start',
      'port_address+port_prichal',
      'id',
    ];
    return this.timeTable?.map(i =>
      fieldRatio.map(j => {
        const [value1, value2] = j.split('+');
        return value2 === 'port_prichal' && i[value2]
          ? `${i[value1]}, причал ${i[value2]}`
          : i[value1];
      }),
    );
  }

  get transferItems() {
    const fieldRatio = [
      'transfer_msg',
      'transfer_city',
      'transfer_time',
      'transfer_address',
      'transfer_type',
    ];
    return this.timeTable?.map(i =>
      fieldRatio.map(j => {
        const [value1, value2] = j.split('+');
        return value2 === 'port_prichal' && i[value2]
          ? `${i[value1]}, причал ${i[value2]}`
          : i[value1];
      }),
    );
  }

  hydrate(data) {
    this.data = data.departuresData;
    const { timetable, cities, ships } = this.data;
    this.timeTable = timetable;
    this.cities = cities;
    this.ships = ships;
  }
}
