import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { setCookie, destroyCookie, parseCookies } from 'nookies';
import Router from 'next/router';
import { removeQueryParam } from '../utils/removeQueryParam';

export class AuthStore {
  api;
  root;
  timer;
  step;
  code;
  phone;
  token;
  formatPhone;
  email;
  group;
  id;
  name;
  patronymic;
  surname;
  toggledModal;
  status;
  strategySignIn;
  statusCodeError;
  showCaptcha;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.step = 'phoneInput'; // phoneInput, codeInput, loginAttempts
    this.counter = 60;
    this.token = '';
    this.strategySignIn = '';
    this.error = null;
    this.status = null;
    this.toggledModal = false;
    this.showCaptcha = false;
    makeAutoObservable(this);
  }

  async retrySendCode(token) {
    const { api } = this;
    const { data } = await api.getSms(this.phone, token);
    if (data.status === 2) {
      this.setStep('loginAttempts');
    }
  }

  async getPhonePolling() {
    const { api } = this;
    await api
      .getPhonePolling(this.phone)
      .then(async res => {
        const { success, token } = res.data;
        if (token) {
          runInAction(() => {
            this.token = token;
          });
          api.setTokenToServer(token).catch(e => {
            console.log('getToken', e);
          });
        } else {
          throw new Error('error code');
        }
        if (success === true) {
          await api.me(this.token).then(response => {
            if (response.data.error) {
              this.error = {
                message: 'Проверьте правильность ввода',
              };
              toast('Ошибка входа!');
            } else {
              this.error = null;

              const { email, group, id, name, patronymic, surname } =
                response.data.user;
              toast('Вы успешно залогинились!');
              this.removeCaptchaCookie();
              runInAction(() => {
                this.email = email;
                this.group = group;
                this.id = id;
                this.name = name;
                this.patronymic = patronymic;
                this.surname = surname;
                this.phone = null;
              });
              this.setToggledModal(false);
              // this.stop();
            }
          });
        } else {
          throw new Error('Ошибка входа в систему');
        }
      })
      .catch(e => {
        console.log('getToken', e);
      });
  }

  setPhone(phone, typePhone = 'formatPhone') {
    runInAction(() => {
      if (typePhone === 'formatPhone') {
        this.formatPhone = phone;
      } else {
        this.phone = phone;
      }
    });
  }

  setStep(step) {
    this.step = step;
  }

  async getCode(token) {
    const { api } = this;
    await api.getSms(this.phone, token, '').then(res => {
      const { status } = res.data;
      if (status === 1) {
        this.setStep('codeInput');
      } else if (status === 2) {
        this.setStep('loginAttempts');
      } else if (status === 3) {
        this.setStep('lackPersonalArea');
      } else {
        throw new Error('error phone');
      }
    });
  }

  async authByCode(code) {
    try {
      this.error = null;
      this.code = code;
      const { api } = this;
      await this.getToken();
      await api.me(this.token).then(res => {
        if (res.data.error) {
          this.error = {
            message: 'Проверьте правильность ввода',
          };
        } else {
          this.error = null;

          const { email, group, id, name, patronymic, surname } = res.data.user;
          toast('Вы успешно залогинились!');
          this.removeCaptchaCookie();
          window.ym(35441960, 'reachGoal', 'authorization_succeed');
          runInAction(() => {
            this.email = email;
            this.group = group;
            this.id = id;
            this.name = name;
            this.patronymic = patronymic;
            this.surname = surname;
          });
          if (
            this.strategySignIn === 'authSteps' &&
            this.statusCodeError === 0
          ) {
            this.root.bookingStore.authUser();
          }
          this.setToggledModal(false);
          this.stop();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getToken() {
    const { api } = this;
    await api
      .getTokenBySms(this.code, this.phone)
      .then(res => {
        const { token, error } = res.data;
        this.statusCodeError = error;
        if (token) {
          runInAction(() => {
            this.token = token;
          });
          api.setTokenToServer(token).catch(e => {
            console.log('getToken', e);
          });
        } else {
          throw new Error('error code');
        }
      })
      .catch(e => {
        console.log('getToken', e);
      });
  }

  removeBookingSteps() {
    const cookies = parseCookies();
    if (cookies.stepBooking) {
      destroyCookie(null, 'stepBooking', {
        path: '/',
      });
    }
  }

  saveSteps(step = 'initial') {
    setCookie(null, 'stepBooking', step, {
      maxAge: 24 * 60 * 60 * 1000,
      path: '/',
    });
  }

  setShowCaptcha(countClick) {
    if (countClick > 0) {
      setCookie(null, 'showCaptcha', 'true', {
        maxAge: 24 * 60 * 60 * 1000,
        path: '/',
      });
    }
    this.getShowCaptcha();
  }

  getShowCaptcha() {
    const getCookies = parseCookies();
    this.showCaptcha = getCookies.showCaptcha === 'true';
  }

  removeCaptchaCookie() {
    const cookies = parseCookies();
    if (cookies.showCaptcha) {
      destroyCookie(null, 'showCaptcha', {
        path: '/',
      });
    }
  }
  logOut() {
    this.api.logOut().then(() => {
      Router.reload();
    });
    this.removeBookingSteps();
  }

  setToggledModal(toggled, strategy) {
    this.toggledModal = toggled;

    if (this.toggledModal) {
      this.setStrategySignIn(strategy);
    } else {
      this.setStrategySignIn('');
    }
  }

  activateB2bMode() {
    setCookie(null, 'role', 'agent', {
      maxAge: 24 * 60 * 60 * 30000,
      path: '/',
    });
  }

  deactivationB2bMode() {
    destroyCookie(null, 'role');
  }

  toggleB2bMode() {
    if (this.isB2bMode) {
      this.deactivationB2bMode();
      removeQueryParam('role');
      Router.reload();
    } else {
      this.activateB2bMode();
      Router.reload();
    }
  }

  setStrategySignIn(strategy) {
    this.strategySignIn = strategy;
  }

  get fullname() {
    let res = '';

    if (this.name) {
      res += this.name;
    }

    if (this.surname) {
      res += ` ${this.surname}`;
    }

    return res;
  }

  get isLoggedIn() {
    return !!this.id;
  }

  get isManager() {
    return this.isLoggedIn && (this.group === '5' || this.group === '7');
  }

  get isAgent() {
    return this.isLoggedIn && this.group === '100';
  }

  get isB2bMode() {
    const { role } = parseCookies();
    return this.isAgent && role === 'agent';
  }

  hydrate(data) {
    if (data) {
      this.email = data.user?.email;
      this.group = data.user?.group;
      this.id = data.user?.id;
      this.name = data.user?.name;
      this.patronymic = data.user?.patronymic;
      this.surname = data.user?.surname;
    }
  }
}
