import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import cc from 'classcat';
import { Input } from '../Input';
import styles from './dropdown.module.sass';
import { SkeletonCover } from '../Skeleton/SkeletonCover';
// import CalendarIcon from '../../public/admin/icons/calendar.svg';
// import ClearAdminInputIcon from '../../public/admin/icons/clean-input.svg';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const DropDown = ({
  value,
  id,
  placeholder,
  label,
  error,
  content,
  isBodyRight,
  clearValue,
  fluid,
  height,
  width,
  landing,
  last,
  disabled,
  firstLoading,
  view,
  onClickField,
  onClearButtonClick,
  trigger = 'input',
  isModalDrop,
  onCloseAction,
  metric,
  url,
  ...props
}) => {
  const ref = useRef();
  const isCompact = view === 'compact';
  const isAdminView = view === 'admin';

  const [open, setOpen] = useState(false);

  useClickAway(ref, () => {
    if (open) {
      setOpen(false);
      if (onCloseAction) {
        onCloseAction();
      }
    }
  });

  return (
    <div ref={ref} className={styles.dropdown}>
      {firstLoading ? (
        <SkeletonCover
          width={800}
          height={62}
          backgroundColor="#FFF"
          className={styles.skeleton}
        />
      ) : (
        <>
          <div className={styles.dropdownControl}>
            {trigger === 'input' ? (
              <Input
                url={url}
                id={id}
                value={value}
                label={label}
                clearValue={clearValue}
                error={error}
                actionOnClick={() => {
                  setOpen(!open);
                  window.ym(35441960, 'reachGoal', metric);
                }}
                landing={landing}
                view={view}
                last={last}
                dropdownIndicator={open}
                disabled={disabled}
                noAnimateLabel={isAdminView}
                readOnly
                onClickField={onClickField}
                onClearButtonClick={onClearButtonClick}
                isModalDrop={isModalDrop}
                {...props}
              />
            ) : (
              <div
                className={styles.picker}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setOpen(!open);
                  onClearButtonClick?.();
                }}
                onKeyDown={() => {
                  setOpen(!open);
                }}
              >
                По дате
                {value && (
                  <>
                    <span> {value}</span>
                    <button>
                      <Icon
                        icon={
                          <IconSVG
                            name={'common/clean-input'}
                            width={24}
                            height={24}
                          />
                        }
                        title=""
                      />
                    </button>
                  </>
                )}
                <Icon
                  icon={
                    <IconSVG name={'common/calendar'} width={18} height={20} />
                  }
                  title=""
                />
              </div>
            )}
            {!isModalDrop && (
              <div
                className={cc({
                  [styles.dropdownContent]: !isCompact,
                  [styles.compactContent]: isCompact,
                  [styles.contentRight]: isBodyRight,
                  [styles.dropdownContentFluid]: fluid,
                  [styles.active]: open && content,
                })}
              >
                {content}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

DropDown.defaultProps = {
  error: undefined,
  isBodyRight: false,
  clearValue: true,
  fluid: true,
};
