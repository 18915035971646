export function formatter(valueInit, sign = '', isPrefixSign = true) {
  const arr = [];
  if (valueInit == null) return null;
  let value = String(Math.abs(valueInit));
  if (value[value.length - 2] === '.') {
    value = `${value}0`;
  }
  const parts = value.split('.');
  let int = parts[0] || '';
  const fract = parts[1] || '';
  int
    .split('')
    .reverse()
    .forEach((c, i, a) => {
      if (i % 3 === 0 && a[i - 1] !== '.') {
        arr.push(' ');
      }
      arr.push(c);
    });
  int = arr.reverse().join('').trim();
  let res = int;
  if (fract.length > 0) {
    res += `.${fract}`;
  }

  const prefix = valueInit < 0 ? '—' : '';

  if (isPrefixSign) {
    return `${prefix}${sign}${res}`;
  }

  return `${prefix}${res}${sign}`;
}
