import React from 'react';
import cc from 'classcat';
import styles from './successform.module.sass';
import { Text } from '../Text';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const SuccessForm = ({ title, text, className }) => (
  <div className={cc([styles.wrapper, { [className]: className }])}>
    <div className={styles.subscribeSuccess}>
      <Icon
        icon={
          <IconSVG name={'common/subscribe-success'} width={48} height={48} />
        }
      />
    </div>
    <Text variant="heading-3" topGap={16}>
      {title}
    </Text>
    <p className={styles.text}>{text}</p>
  </div>
);
