import React from 'react';
// import styles from './icon.module.sass';

/**
 * Комопнент для иконок
 */
export const Icon = ({
  icon,
  leftGap,
  rightGap,
  topGap,
  bottomGap,
  title,
  // width,
  // height,
  ...props
}) => (
  <div
    alt={title}
    title={title}
    style={{
      // maxWidth: width,
      // maxHeight: height,
      marginLeft: leftGap,
      marginRight: rightGap,
      marginTop: topGap,
      marginBottom: bottomGap,
    }}
    // src={icon}
    {...props}
  >
    {icon}
  </div>
);

Icon.defaultProps = {
  topGap: null,
  bottomGap: null,
  leftGap: null,
  rightGap: null,
  width: null,
  height: null,
  title: 'иконка',
};
