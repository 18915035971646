import { makeAutoObservable, runInAction } from 'mobx';
import { getUtm, getUtmByName } from '../utils/utmHelper';

export class MarketingStore {
  api;
  root;
  isSea;
  departureCities;
  popularDirections;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.isLoading = false;
    this.popularDirections = [];
    this.departureCities = [];
    makeAutoObservable(this);
  }

  async sendUtm(phone, name) {
    const { api } = this;

    const utm = getUtm();

    const utmSource = getUtmByName(utm, 'utm_source');
    const utmMedium = getUtmByName(utm, 'utm_medium');
    const utmCampaign = getUtmByName(utm, 'utm_campaign');
    const utmContent = getUtmByName(utm, 'utm_content');
    const utmTerm = getUtmByName(utm, 'utm_term');

    await api.sendCertificateForm(
      phone,
      name,
      utmSource?.value ?? '',
      utmMedium?.value ?? '',
      utmCampaign?.value ?? '',
      utmContent?.value ?? '',
      utmTerm?.value ?? '',
    );
  }

  setIsSea(isSea) {
    this.isSea = isSea ? 1 : 0;
  }

  async getRiverDistention() {
    const { api } = this;
    this.isLoading = true;
    await api.getPupulardistantion(this.isSea).then(({ data }) => {
      runInAction(() => {
        this.popularDirections = data.popularDirections;
        this.departureCities = data.departureCities;
        this.isLoading = false;
      });
    });
  }

  hydrate(data) {
    this.data = data;
  }
}
