import { isClient } from '../utils/isClient';

export const yandexDataLayer = (type, product) => {
  if (isClient) {
    window.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        [type]: {
          products: [product],
        },
      },
    });
  }
};

export const yandexPurchase = (type, actionField, product) => {
  if (isClient) {
    window.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        [type]: {
          actionField,
          products: [product],
        },
      },
    });
  }
};
export const yandexUserLayer = (userId, userType, isSea) => {
  const typeUser = type => {
    switch (type) {
      case '4':
        return 'customer';
      case '5':
        return 'admin';
      case '7':
        return 'developer';
      default:
        return 'unlogged';
    }
  };

  if (isClient) {
    window.ym(35441960, 'userParams', {
      user_info: {
        user_id: userId,
        user_type: typeUser(userType),
      },
    });
    window.ym(35441960, 'params', {
      product_info: {
        product_page_type: isSea ? 'sea' : 'river',
      },
    });
  }
};
