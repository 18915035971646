import { isClient } from '../utils/isClient';

export const gaHelper = (section, value) => {
  if (isClient) {
    // eslint-disable-next-line no-undef
    window.gtag('event', value, {
      event_category: 'user',
      event_label: section,
    });
  }
};
