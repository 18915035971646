import React, { useRef, memo, useEffect } from 'react';
import { useClickAway, useKey } from 'react-use';
import cc from 'classcat';
// import { UniversalPortal } from '@jesstelford/react-portal-universal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Text } from '../Text';
import { Icon } from '../Icon';
import styles from './modal.module.sass';
import { Badge } from '../Badge';
import { IconSVG } from '../SpriteSVG';

export const useBodyScrollLock = targetElement => {
  useEffect(() => {
    if (!targetElement) return;

    disableBodyScroll(targetElement, {
      reserveScrollBarGap: true,
    });

    // eslint-disable-next-line consistent-return
    return () => enableBodyScroll(targetElement);
  }, [targetElement]);
};

/**
 * Модальное окно
 */
export const Modal = memo(
  ({
    children,
    isOpen,
    title,
    info,
    disabledOverflow,
    onOpen,
    fullScreen,
    props,
    onCloseAction,
    hideCross,
    back,
    size = 'default',
    special,
    actionOnOpen,
    actions,
    isMobile,
    noClickAway,
    centerHeader,
    bottomModal,
    url,
  }) => {
    const ref = useRef(null);
    const closeModal = () => {
      onOpen(false);
      if (isOpen && onCloseAction) {
        onCloseAction();
      }
    };

    const clickAwayHandler = () => {
      if (!noClickAway && !isMobile) {
        // Проверяем noClickAway внутри обработчика
        closeModal();
      }
    };

    useKey('Escape', closeModal);

    useClickAway(ref, clickAwayHandler);

    useBodyScrollLock(isOpen ? ref.current : null);

    useEffect(() => {
      if (isOpen && actionOnOpen) {
        actionOnOpen();
      }
    }, [isOpen]);
    // TODO: Свойство для блокировки модалки. Если стоит это свойство, то по клику вне модалки, окно не закрывается

    return (
      <div
        className={cc([
          styles.overlay,
          {
            [styles.overlayBottom]: bottomModal,
            [styles.isOpen]: isOpen,
          },
        ])}
        {...props}
      >
        <div
          ref={ref}
          tabIndex="-1"
          className={cc([
            styles[size],
            !special ? styles.content : styles.special,
            {
              [styles.fullScreen]: fullScreen,
              [styles.contentDisabledOverflow]: disabledOverflow,
              [styles.bottomModal]: bottomModal,
              [styles.contentBf]: url === 'black_friday',
            },
          ])}
        >
          <div
            className={cc([
              !special ? styles.header : styles.headerSpecial,
              { [styles.headerCentered]: centerHeader },
              { [styles[size]]: fullScreen },
              { [styles.headerBf]: url === 'black_friday' },
            ])}
          >
            <div className={styles.headerWrapActionsText}>
              {back && (
                <button className={styles.backBtn} onClick={() => closeModal()}>
                  <Icon
                    icon={
                      <IconSVG name={'common/back'} width="9" height="17" />
                    }
                    title="Назад"
                  />
                </button>
              )}
              {url === 'black_friday' ? (
                <Text variant="countBF">{title}</Text>
              ) : (
                <Text variant={fullScreen ? 'heading-5' : 'heading-2'}>
                  {title}
                </Text>
              )}

              {info && (
                <div className={styles.badgeWrapper}>
                  <Badge view="info" size="smUltra">
                    {info}
                  </Badge>
                </div>
              )}
            </div>
            <div className={styles.headerWrapActions}>
              <div>{actions}</div>
              <div>
                {!hideCross && (
                  <button
                    onClick={() => closeModal()}
                    className={styles.headerActions}
                    type="button"
                  >
                    <div
                      className={
                        url === 'black_friday' ? styles.closeBf : styles.close
                      }
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={cc({ [styles[size]]: fullScreen })}>{children}</div>
        </div>
      </div>
    );
  },
);

Modal.defaultProps = {
  topGap: 0,
  bottomGap: 0,
  size: 'default',
  info: null,
  subButton: null,
  actions: null,
  fullScreen: false,
  hideCross: false,
  onCloseAction: null,
  centerHeader: false,
};
