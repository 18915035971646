import { yaHelper } from './yaHelper';
import { gaHelper } from './gaHelper';

export const YaAndGaHelper = (section, value) => {
  yaHelper(section, value);
  gaHelper(section, value);
};

/* Helper для определенной секции, Данек, для пояснения пиши мне */
export const YaAndGaSearchLine = value => {
  YaAndGaHelper('Search_line', value);
};

export const YaAndGaHeader = value => {
  YaAndGaHelper('Header', value);
};

export const YaAndGaFooter = value => {
  YaAndGaHelper('Footer', value);
};

export const YaAndGaMainPage = value => {
  YaAndGaHelper('Main_page', value);
};

export const YaAndGaShipPage = value => {
  YaAndGaHelper('Ship_page', value);
};

export const YaAndGaCruisePage = value => {
  YaAndGaHelper('Cruise_page', value);
};

export const YaAndGaResultPage = value => {
  YaAndGaHelper('Result_page', value);
};

export const YaAndGaPopdirPage = value => {
  YaAndGaHelper('Popdir_page', value);
};

export const YaAndGaStepPage = value => {
  YaAndGaHelper('Step_page', value);
};
