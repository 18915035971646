import { makeAutoObservable } from 'mobx';

export class AdminStore {
  api;
  root;
  data;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.orders = {
      data: [],
    };
    makeAutoObservable(this);
  }

  *getOrders() {
    try {
      const { data: payload } = yield this.api.getOrders();

      this.orders = payload;
    } catch {}
  }
  hydrate(data) {
    this.orders = data.orders;
  }
}
