import { isClient } from '../utils/isClient';

export const StorageNames = {
  Favorite: 'cart',
  Filters: 'filters',
};

Object.freeze(StorageNames);

export class StorageManager {
  static get = name => {

    if (isClient && name) {
      try {
        return JSON.parse(localStorage.getItem(name));
      } catch {
        return null
      }
    }

    return null;
  };

  static set = (name, data) => {
    if (isClient && name && data) {
      localStorage.setItem(name, JSON.stringify(data));
    }
  };

  static delete = name => {
    if (isClient && name) {
      localStorage.removeItem(name);
    }
  };
}
