import { makeAutoObservable } from 'mobx';
import Router from 'next/router';
import { CreateParamsFilters } from '../utils/useParamsFilters';
import { CruiseType } from '../components/SearchCruises/SearchCruises';
import { yandexDataLayer } from '../lib/yandexDataLayer';

export class ShipsSearchStore {
  api;
  root;
  amount;
  isLoading;
  shipsList = [];
  filterClasses = [];
  filterDecks;
  filterServices = [];
  paramsFilters;
  page;
  filters;

  data;

  filterByDecks = [];
  filterByShips = [];
  isLiners;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.isLoading = false;
    this.shipsList = [];
    this.filterClasses = [];
    this.filterDecks = [];
    this.filterServices = [];
    this.amount = 0;
    this.page = 1;
    this.paramsFilters = new CreateParamsFilters();
    makeAutoObservable(this);
  }

  async setPage() {
    this.page += 1;
    this.paramsFilters.search_page = this.page;
    await this.getList();
  }

  async getList(type = CruiseType.Rivers) {
    const { api } = this;

    const directions = {
      [CruiseType.Rivers]: 1,
      [CruiseType.Sea]: 2,
    };

    try {
      const { data } = await api.getList(
        {
          ...this.paramsFilters.paramsFilters,
          search_page: this.paramsFilters.search_page,
        },
        this.isLiners ? 2 : 1,
      );
      this.data = data;
      this.shipsList = [...data.shipsList];
      this.filterClasses = [...data.filterClasses].reverse();
      this.filterDecks = [...data.filterDecks];
      this.filterServices = [...data.filterServices];
    } catch (error) {
      console.error(error);
    }
  }

  async getInitialData() {
    this.isLoading = true;

    try {
      const { data } = await this.api.getList(
        {
          ...this.paramsFilters.paramsFilters,
          search_page: this.paramsFilters.search_page,
        },
        this.isLiners ? 2 : 1,
      );

      this.data = data;
      this.shipsList = [...data.shipsList];
      this.filterClasses = [...data.filterClasses].reverse();
      this.filterDecks = [...data.filterDecks];
      this.filterServices = [...data.filterServices];
      this.isLoading = false;

      const products = this.shipsList.map((ship, index) => ({
        id: ship.id,
        name: ship.name,
        brand: ship.company || '',
        category: ship.class.lable,
        position: index + 1,
        quantity: 1,
      }));
    // Отправляем данные в Яндекс Метрику
    yandexDataLayer('impressions', products);
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  removeBlankProp(obj) {
    return Object.entries(obj)
      .filter(([, v]) => v !== '')
      .filter(([, v]) => v !== 0)
      .filter(([, v]) => v !== '0')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  async paramsToUrl() {
    const { push, pathname, query } = Router;

    return await push(
      {
        pathname,
        query: this.removeBlankProp({ ...query, ...this.params }),
      },
      undefined,
      { scroll: false, shallow: true },
    );
  }

  async addTypeToUrl(url) {
    const { pathname } = Router;

    await Router.push(`${pathname}?$type=${url}`, undefined, {
      shallow: true,
      scroll: false,
    });
  }

  clearFilters() {
    this.filterClasses = [...this.data.filterClasses].reverse();
    this.filterDecks = [...this.data.filterDecks];
    this.filterServices = [...this.data.filterServices];
    this.filterByDecks = [];
    this.filterByShips = [];
    this.page = 1;
    this.paramsFilters.search_page = 1;
  }

  setFilters(filters) {
    this.filters = filters;
    this.paramsToUrl();
  }

  setParamsFilters = params => {
    this.filterClasses = this.filterClasses.map(classItem => ({
      ...classItem,
      checked: params.filterClasses.includes(String(classItem.value)),
    }));
    this.filterServices = this.filterServices.map(classItem => ({
      ...classItem,
      checked: params.filterServices.includes(String(classItem.value)),
    }));
    this.filters = {
      ...this.filters,
      sort: params.sort,
    };
  };

  setBottomFilters(filters) {
    this.bottomFilters = filters;
    this.paramsToUrl();
  }

  filterByDecksAndShips(decks, ships) {
    this.filterByDecks = decks;
    this.filterByShips = ships;
  }

  get filteredShips() {
    const classes = this.filterClasses
      .filter(classItem => classItem.checked)
      .map(({ value }) => value);
    const services = this.filterServices
      .filter(classItem => classItem.checked)
      .map(({ value }) => value);

    let sortedShips = [...this.shipsList].sort(
      (a, b) =>
        a[this.filters?.sort?.sort_mode] - b[this.filters?.sort?.sort_mode],
    );

    if (classes.length) {
      sortedShips = sortedShips.filter(ship =>
        classes.includes(ship.class.value),
      );
    }

    if (services.length) {
      sortedShips = sortedShips.filter(ship =>
        ship?.services?.some(({ value }) => services.includes(value)),
      );
    }

    if (this.filterByDecks.length) {
      sortedShips = sortedShips.filter(ship =>
        this.filterByDecks.includes(String(ship.deck_count)),
      );
    }

    if (this.filterByShips.length) {
      sortedShips = sortedShips.filter(ship =>
        this.filterByShips.includes(ship.name),
      );
    }

    return sortedShips;
  }

  hydrate(data = {}) {
    this.isLiners = data.liners;
  }
}
