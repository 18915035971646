//@ts-nocheck
import { enableStaticRendering } from 'mobx-react-lite';
import React, {  createContext, useContext } from 'react';
import { RootSearch } from '../stores/DI/RootSearch.store';
import { IHydratedStore, IStoreProviderProps } from 'typings/types';

enableStaticRendering(typeof window === 'undefined');
let store: IStoreProviderProps;
const StoreContext = createContext<IHydratedStore | undefined>(undefined);

export function useSearchStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useSearchStore must be used within SearchStoreProvider');
  }

  return context;
}

// export const SearchStoreProvider: FC<IStoreProviderProps> = ({
//   children,
//   initialState,
// }) => {
//   const initStore = initializeStore(initialState, new RootSearch);

//   return (
//     <StoreContext.Provider value={initStore}>{children}</StoreContext.Provider>
//   );
// };

export function initializeStore(initialData): IStoreProviderProps {
  const _store = store ?? new RootSearch();

  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}


export function SearchStoreProvider({
  children,
  hydrationData,
}: {
  children: ReactNode;
  hydrationData?: RootStoreHydration;
}) {
  const store = initializeStore(hydrationData);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}
