import { makeAutoObservable } from 'mobx';

export class PromoCode {
  root;
  api;
  promoCode;
  promoPercent;
  promoName;
  promoPrice;
  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.promoCode = '';

    makeAutoObservable(this);
  }

  async submitPromoCode(orderId, promo) {
    const { api } = this;
    await api.submitPromoCode(orderId, promo).then(res => {
      this.promoCode = res.data;
    });
  }

  setPromoPercent(promoPercent) {
    this.promoPercent = promoPercent;
  }
  setPromoName(promoName) {
    this.promoName = promoName;
  }
  setPromoPrice(promoPrice) {
    this.promoPrice = promoPrice;
  }
  setPromoCode(promoCode) {
    this.promoCode = promoCode;
  }
}
