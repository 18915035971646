import React from 'react';
import { Form, Formik } from 'formik';
import NumberFormat from 'react-input-mask';
import { Else, If, Then } from 'react-if';
import cc from 'classcat';
import * as Yup from 'yup';
import nookies from 'nookies';
import styles from './subscribe.module.sass';
import { Text } from '../../Text';
import { Input } from '../../Input';
import { Button } from '../../Button';
import { unFormatterPhone } from '../../../utils/unFormatterPhone';
import { oldApi } from '../../../lib/api';
import { SuccessForm } from '../../SuccessForm';
import { YaAndGaMainPage } from '../../../lib/YaAndGaHelper';
import { useAuthStore } from '../../../providers/RootStoreProvider';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Имя должно содержать больше 2 символов')
    .max(25, 'Имя не должно содержать больше 25 символов')
    .matches(/^[а-яА-ЯёЁa-zA-Z\s]+$/, 'Введите корректное Имя и Фамилию')
    .trim()
    .required('Обязательное поле'),
  phone: Yup.string()
    .trim()
    .min(11, 'Введите номер телефона')
    .max(11, '')
    .required('Обязательное поле'),
});

export const Subscribe = ({ isSeason }) => {
  const { id, group, isManager } = useAuthStore();
  const user = { id, group };
  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={{
          name: '',
          phone: '',
          formatPhone: '',
          success: false,
          error: false,
        }}
        validationSchema={schema}
        onSubmit={async ({ name, formatPhone: phone }, { setFieldValue }) => {
          try {
            await oldApi.post('/main_rest/callback_email_react', {
              name,
              phone,
              user_id: user?.id || null,
              ym_cid: !isManager ? nookies.get(null)?._ym_uid || null : null,
            });

            setFieldValue('success', true);
          } catch (e) {
            setFieldValue('error', true);
          }
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <If condition={!values.success}>
            <Then>
              <Form>
                <div className={styles.secondHeader}>
                  <Text variant="heading-3">
                    {isSeason
                      ? 'На данный момент сезонных предложений нет'
                      : 'Заполните форму обратной связи, и мы свяжемся с вами в кратчайшие сроки'}
                  </Text>
                </div>
                <div className={styles.secondHeader}>
                  <Text variant="subText">
                    Хотели бы вы задать нам вопросы, поделиться предложениями
                    или оставить комментарии? Обсудим все ваши вопросы и
                    потребности.
                  </Text>
                </div>

                <If condition={values.error}>
                  <Then>
                    <p className={styles.error}>
                      Что то пошло не так, попробуйте снова.
                    </p>
                  </Then>
                </If>
                <div
                  className={cc([styles.body, { [styles.season]: isSeason }])}
                >
                  <Input
                    placeholder="Имя и фамилия"
                    id="name"
                    label="Имя и фамилия"
                    error={touched.name && errors.name}
                    onClick={() => {
                      YaAndGaMainPage(
                        'click_first_name_and_last_name_in_form_of_selection',
                      );
                    }}
                  />
                  <Input
                    control={NumberFormat}
                    values={values.phone}
                    label="Номер телефона"
                    id="phone"
                    mask="+7 999 999-99-99"
                    onChange={e => {
                      setFieldValue('formatPhone', e.target.value);
                      setFieldValue('phone', unFormatterPhone(e.target.value));
                    }}
                    error={touched.phone && errors.phone}
                    onClick={() => {
                      YaAndGaMainPage('click_phone_in_form_of_selection');
                    }}
                  />
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isSubmitting}
                    fullWidth
                    onClick={() => {
                      YaAndGaMainPage('click_button_find_in_form_of_selection');
                      window.ym(35441960, 'reachGoal', 'selection_cruise');
                    }}
                  >
                    {isSeason ? 'Оставить заявку' : 'Отправить'}
                  </Button>
                </div>
                <p className={styles.agreement}>
                  Нажимая «Отправить» вы принимаете Пользовательское соглашение
                  и даёте согласие на обработку ваших данных
                </p>
              </Form>
            </Then>
            <Else>
              <div className={styles.success}>
                <SuccessForm
                  title={
                    <span>
                      Все на борт! <br />
                      {values.name}, заявка отправлена!
                    </span>
                  }
                  text="В ближайшее время наш менеджер позвонит вам по указанному номеру"
                />
              </div>
            </Else>
          </If>
        )}
      </Formik>
    </div>
  );
};
