export const formatBytes = (bytes, decimal = 2, type = 'transformation') => {
  if (bytes === 0) {
    return 0;
  }
  const k = 1024;
  const dm = decimal < 0 ? 0 : decimal;
  const sizesType = ['байт', 'КБ', 'МБ'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (type === 'examination') {
    if (sizesType[i] === 'МБ') {
      // eslint-disable-next-line no-restricted-properties
      if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) > 5) {
        return true;
      }
    }
    return false;
  }
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizesType[i]}`;
};
