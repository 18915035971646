import React from 'react';
import ContentLoader from 'react-content-loader';

export const SkeletonCover = ({
  width,
  height,
  speed = 1,
  animate = true,
  backgroundColor = '#F3F6F9',
  animateColor = '#F9F9F9',
  ...props
}) => (
  <ContentLoader
    speed={speed}
    width={width}
    height={height}
    animate={animate}
    backgroundColor={backgroundColor}
    foregroundColor={animateColor}
    style={{
      width: '100%',
      // height: 'auto',
    }}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </ContentLoader>
);
