import React, { useRef, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { useClickAway } from 'react-use';
import cc from 'classcat';
import { useFormikContext } from 'formik';
import styles from './tabs.module.sass';

// Если мобилка, то дроп, а так табы
export const Tabs = ({
  name,
  items,
  isMobile,
  onReset,
  disabled,
  isBooking,
  withoutBG,
  actionOnChange,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const active = values[name];

  useClickAway(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  const changeHandler = value => {
    if (value !== active) {
      if (onReset) onReset();
      setFieldValue(name, value);
      actionOnChange();
    }
  };

  return !isBooking ? (
    <div
      className={cc([
        styles.wrapper,
        { [styles.mobile]: isMobile, [styles.wrapperWithoutBG]: withoutBG },
      ])}
      {...props}
    >
      <div className={styles.list}>
        <If condition={!isMobile}>
          <Then>
            {items.map(({ title, value }) => (
              <button
                key={title}
                className={cc([
                  styles.item,
                  { [styles.active]: active === value },
                ])}
                onClick={() => changeHandler(value)}
                disabled={disabled}
              >
                {title}
              </button>
            ))}
          </Then>
          <Else>
            <div className={styles.dropdownMob} ref={ref}>
              <button
                className={cc([styles.item, styles.mobile])}
                onClick={() => setIsOpen(!isOpen)}
              >
                {items.find(i => i.value === active).title}
              </button>
              {isOpen && (
                <div className={styles.body}>
                  {items
                    .filter(i => i.value !== active)
                    .map(i => (
                      <button
                        onClick={() => {
                          if (onReset) onReset();
                          setFieldValue(name, i.value);
                          setIsOpen(false);
                          changeHandler(i.value);
                        }}
                        disabled={disabled}
                      >
                        {i.title}
                      </button>
                    ))}
                </div>
              )}
            </div>
          </Else>
        </If>
      </div>
    </div>
  ) : (
    <div className={styles.booking}>
      {items.map(i => (
        <div className={styles.bookingButton}>
          <div>
            <button
              key={i.title}
              className={cc([
                styles.bookingItem,
                { [styles.bookingActive]: active === i.title },
              ])}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {i.title}
            </button>
          </div>
        </div>
      ))}
      {isOpen && (
        <div className={styles.body}>
          {items
            .filter(i => i.value !== active)
            .map(i => (
              <button
                onClick={() => {
                  if (onReset) onReset();
                }}
                disabled={disabled}
              >
                {i.title}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

Tabs.defaultProps = {
  isMobile: false,
  disabled: false,
  withoutBG: false,
};
