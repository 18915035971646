import React from 'react';
import cc from 'classcat';
import styles from './button.module.sass';

/**
 * Компонент для кнопок
 */
export const Button = ({
  type = 'button',
  children,
  disabled,
  size,
  fullWidth,
  topGap,
  view,
  background,
  className,
  ...props
}) => (
  <button
    type={type}
    disabled={disabled}
    className={cc([
      styles.button,
      styles[size],
      styles[view],
      styles[background],
      {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
        [styles.topGap]: topGap,
        [className]: !!className,
      },
    ])}
    {...props}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: 'button',
  children: null,
  size: 'md',
  topGap: false,
  fullWidth: false,
  view: null,
  background: null,
  className: null,
};
