import { makeAutoObservable } from 'mobx';
import isNumber from 'lodash/isNumber';
import Router from 'next/router';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { isClient } from '../utils/isClient';
import { removeDuplicates } from '../utils/removeDuplicates';
import { translit } from '../utils/translit';

const isBrowser = typeof window !== 'undefined';
export class SeaCruiseStore {
  root;
  data;
  cabins;
  loadingCabin;
  loadingNumberCautes;
  outputTree;
  isOpenModal;
  cabinNumbers;
  cabin;
  tariff;
  spaciousness;
  nameTarrif;
  token;
  capacity;
  fio = null;
  phone = null;
  bitrixId = null;
  price;
  adults;
  teenager;
  child;
  infoPrice;
  indexDetailPrice;
  activeTab;
  isShowTooltip;
  dataTooltip;
  astoriaActiveCabin;
  astoriaActiveCategory;
  seaRequestStatus;
  available;
  totalDetail;
  selectedCabinsStorage;
  cruiseStorage;
  astoriaPriceLoader;

  constructor(root, api) {
    this.api = api;
    this.root = root;
    this.cabins = [];
    this.activeTab = 'scheme';
    this.spaciousness = 2;
    this.loadingCabin = false;
    this.loadingNumberCautes = false;
    this.token = '';
    this.tariff = {
      name: '',
      valueCurrent: null,
      price: null,
      detailedPrice: [],
    };
    this.outputTree = [];
    this.cabinNumbers = [];
    this.isOpenModal = false;
    this.activeCabin = [];
    this.astoriaFreeCabins = [];
    this.astoriaActiveCabin = [];
    this.astoriaAllData = [];
    this.astoriaActiveCategory = [];
    this.isShowTooltip = false;
    this.fio = '';
    this.phone = '';
    this.bitrixId = '';
    this.nameTarrif = '';
    this.price = 0;
    this.infoPrice = [];
    this.indexDetailPrice = 0;
    this.available = '';
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'SeaCruizStore',
      storage: isBrowser ? window.localStorage : undefined,
      properties: [
        'selectedCabinsStorage',
        'fio',
        'phone',
        'bitrixId',
        'cruiseStorage',
      ],
    });
  }

  stopStore() {
    stopPersisting(this);
  }

  setFio(fio) {
    this.fio = fio;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  setBitrixId(id) {
    this.bitrixId = id;
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  setCabins(items) {
    this.cabins = items;

    // Подготовка структуры для вывода в компонент
    const types = Array.isArray(this.cabins)
      ? removeDuplicates(
          this.cabins
            .map(i => i.category_info.root_type)
            .filter(i => i !== undefined),
        )
      : [];

    this.outputTree = types.map(i =>
      items.filter(j => j.category_info.root_type === i),
    );

    const availableArray = this.outputTree.map(i => i.map(j => j))[0];
    this.available = availableArray?.map(i => i.available)[0];
  }

  setClearUrl() {
    Router.push(
      {
        pathname: `/sea_cruise/${Router.query.id}`,
      },
      undefined,
      { scroll: false, shallow: true },
    );
  }

  setAstoriaPrice(price) {
    this.price = price;
  }

  setAstoriaDetailPrice(detail) {
    this.infoPrice = detail;
  }

  setIndexDetailPrice(index) {
    this.indexDetailPrice = index;
  }

  setInitTarrif(name, valueCurrent, price, detailedPrice) {
    this.tariff = {
      name,
      valueCurrent,
      price,
      detailedPrice,
    };
    this.setNameTariff(name);
  }

  setCabinNumbers(data) {
    if (Array.isArray(data)) {
      this.cabinNumbers = data;
      this.cabin = this.cabinNumbers.length && this.cabinNumbers[0].CabinNo;
    } else this.cabin = data;
  }

  setTariffFilter(items) {
    const filterSetting = this.activeCabin?.price?.map(i => i.tariff);

    const filtrationTariff = items?.map(i =>
      filterSetting?.map(item => (i.title === item ? i : '')),
    );

    const clearTariff = filtrationTariff?.map(i =>
      i?.filter(item => item !== ''),
    );

    const checkTariff = clearTariff?.filter(i => i?.length !== 0);

    const readyTariff = checkTariff?.map(i => i?.[0]);

    return readyTariff;
  }

  setCapacity(capacity) {
    this.capacity = capacity;
  }

  // get filteredCabinsByCapacity() {
  //   return this.cabins.filter(i => i.capacity !== this.capacity);
  // }

  get hasCapacity() {
    return (
      Array.isArray(this.cabins) && this.cabins?.some(i => isNumber(i.capacity))
    );
  }

  clearCabinNumbers() {
    this.cabinNumbers = [];
  }

  toggleModal = async (toggled, params = {}) => {
    this.isOpenModal = toggled;
    this.loadingNumberCautes = !this.hasCabinInAvailableCabins;
    const { astoria } = params;
    const { api } = this;

    const {
      query: { id },
    } = Router;
    try {
      const res = await api.astoriaPrice(
        id,
        1,
        this.teenager ?? 0,
        this.child ?? 0,
        this.activeCabin.capacity,
        this.activeCabin.category_info.category,
      );
      this.setAstoriaPrice(res.data.price);
      this.setAstoriaDetailPrice(res.data.price_detailed);
    } catch (error) {
      console.error(error);
    }

    const { getInfoCabin } = this.api;
    if (toggled) {
      const categoryCode = !astoria
        ? await this.activeCabin?.category_info?.category
        : this.astoriaActiveCabin.CategoryId;
      const componentId = !astoria
        ? await this.activeCabin?.component_id
        : this.astoriaActiveCabin?.component_id;
      const vendorId = !astoria
        ? await this.activeCabin?.vendor_id
        : this.astoriaActiveCabin?.vendor_id;
      if (!this.hasCabinInAvailableCabins) {
        await getInfoCabin(categoryCode, componentId, vendorId)
          .then(data => {
            this.setInitTarrif(
              this.activeCabin?.price[0].tariff,
              this.activeCabin?.price[0].value_curr,
              this.activeCabin?.price[0].value_rub,
              this.activeCabin?.price[0].detailed_price,
            );
            this.setCabinNumbers(data.data);
          })
          .finally(() => {
            this.loadingNumberCautes = false;
          });
      }
    } else {
      // await Router.push(
      //   {
      //     pathname: `/sea_cruise/${Router.query.id}`,
      //     query: {},
      //   },
      //   undefined,
      //   { scroll: false, shallow: true },
      // );
      this.clearCabinNumbers();
      this.clearActiveCabin();
    }
  };

  setActiveAstoriaCabin(cabin, category) {
    if (typeof cabin === 'object') {
      this.astoriaActiveCabin = { ...cabin, ...category };
    } else if (typeof cabin === 'string') {
      const dataCab =
        this.astoriaFreeCabins &&
        Object.values(this.astoriaFreeCabins).find(
          x => x.CabinNo.toString() === cabin,
        );
      const fullCab =
        dataCab &&
        Object.values(this.astoriaAllData).find(
          x => x.CategoryId === dataCab.CategoryId,
        );

      this.setActiveAstoriaCabin(dataCab, fullCab);
      this.setCabinNumbers(dataCab.CabinNo);
    }
  }

  async astoriaPickCabin(e, params = {}) {
    const { scheme } = params;

    if (!scheme) {
      this.astoriaActiveCabin(this.activeCabin.category_info?.id);

      await Router.push(
        {
          pathname: `/sea_cruise/${Router.query.id}`,
          query: { cabin: this.astoriaActiveCabin?.CategoryId },
        },
        undefined,
        { scroll: false, shallow: true },
      );
    } else {
      const translitActiveCabin = translit(e.target.innerHTML).toLowerCase();
      const clickToCabin = e.target?.className?.animVal?.includes('CautaClick');

      if (clickToCabin) {
        const astoriaCabinsInfo =
          this.astoriaFreeCabins &&
          Object.values(this.astoriaFreeCabins).find(
            x => x.CabinNo.toString() === translitActiveCabin,
          );

        const astoriaCategoryInfo =
          astoriaCabinsInfo &&
          Object.values(this.astoriaAllData).find(
            x => x.CategoryId === astoriaCabinsInfo.CategoryId,
          );
        this.setActiveAstoriaCabin(astoriaCabinsInfo, astoriaCategoryInfo);
        this.setCabinNumbers(astoriaCabinsInfo?.CabinNo);

        await Router.push(
          {
            pathname: `/sea_cruise/${Router.query.id}`,
            query: { cabin: this.astoriaActiveCabin?.CabinNo },
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }
    }
  }

  setActiveCabin(data) {
    this.activeCabin = data;
  }

  clearActiveCabin() {
    this.activeCabin = null;
  }

  setNameTariff(tariff) {
    this.nameTarrif = tariff;
  }

  setSeaTariff(tariff) {
    const nameTarrif = tariff;
    const selectTariff = this.activeCabin?.price.find(
      t => t.tariff === nameTarrif,
    );
    this.setInitTarrif(
      selectTariff?.tariff,
      selectTariff?.value_curr,
      selectTariff?.value_rub,
      selectTariff?.detailed_price,
    );
  }

  setSeaCabin(cabin) {
    this.cabin = cabin;
  }

  setSpaciousness(spaciousness) {
    this.spaciousness = spaciousness;
  }

  setCountePassanger(adults, teenager, child) {
    this.adults = adults;
    this.teenager = teenager;
    this.child = child;
  }

  get totalPassanger() {
    return this?.adults + this.teenager + this.child;
  }

  get isPassangersLimit() {
    return this?.totalPassanger === this?.activeCabin?.capacity;
  }

  get hasOneAdults() {
    return this.adults === 1;
  }

  get isAstoria() {
    return this?.activeCabin?.vendor_id === 100022;
  }

  async getRequestStatus(fio, phone, cruisId, categoryId, categoryName) {
    const { api } = this;
    await api.seaRequest(fio, phone, cruisId, categoryId, categoryName);
  }

  updateAstoriaPrice = async () => {
    const { api } = this;
    this.astoriaPriceLoader = true;
    const {
      query: { id },
    } = Router;
    try {
      const res = await api.astoriaPrice(
        id,
        this.adults,
        this.teenager,
        this.child,
        this.activeCabin.capacity,
        this.activeCabin.category_info.category,
      );
      this.setAstoriaPrice(res.data.price);
      this.setAstoriaDetailPrice(res.data.price_detailed);
      this.astoriaPriceLoader = false;
    } catch (error) {
      console.error(error);
      this.astoriaPriceLoader = false;
    }
  };

  get dataToBooking() {
    const { isManager, name, patronymic, isB2bMode } = this.root.authStore;
    const {
      query: { id },
    } = Router;
    return {
      cruise_type: 2,
      cruise_id: id,
      user_token: this.token,
      manager_user_phone: isManager || isB2bMode ? name : '',
      manager_user_fio: isManager || isB2bMode ? patronymic : '',
      order_price: this.tariff.price,
      cabin_numbers: [this.cabin],
      cabin_classes: [this.activeCabin.category_info?.category],
    };
  }

  async temporaryLinkForBooking() {
    const { api } = this;

    this.root.bookingStore.clearOrderId();

    const { isLoggedIn } = this.root.authStore;

    await api
      .sendCabInfo({
        cabinNo: this.cabin,
        tarif: this.activeCabin?.category_info?.category,
        price: this?.tariff?.price,
        spaciousness: this.spaciousness,
        cruiseId: isClient && Router?.query?.id,
        nameTarrif: this.nameTarrif,
        fio: this.fio,
        phone: this.phone,
        bitrixId: this.bitrixId,
        priceCur: this.tariff.valueCurrent,
      })
      .then(res => {
        if (res.status === 200) {
          this.selectedCabinsStorage = res.data.cab_content;
          this.cruiseStorage = res.data.cruise_data;
          if (!isLoggedIn) {
            Router.push(
              {
                pathname: `/step_1`,
              },
              undefined,
              { scroll: false },
            );
          } else {
            Router.push(
              {
                pathname: `/step_2`,
              },
              undefined,
              { scroll: false },
            );
          }
        }
      });
  }

  async astoriaLinkForBooking() {
    const { api } = this;

    this.root.bookingStore.clearOrderId();

    const { isLoggedIn } = this.root.authStore;

    await api
      .sendCabInfo({
        cabinNo: this.cabin,
        tarif: this.activeCabin?.category_info?.category,
        price: this?.price !== 0 ? this?.price : this?.tariff?.price,
        spaciousness: this.spaciousness,
        cruiseId: isClient && Router?.query?.id,
        nameTarrif: `Взрослых:${this.adults} Подростоков:${this.teenager} Детей:${this.child}`,
        fio: this.fio,
        phone: this.phone,
        bitrixId: this.bitrixId,
        priceCur: this.tariff.valueCurrent,
      })
      .then(res => {
        if (res.status === 200) {
          this.selectedCabinsStorage = res.data.cab_content;
          this.cruiseStorage = res.data.cruise_data;
          if (!isLoggedIn) {
            Router.push(
              {
                pathname: `/step_1`,
              },
              undefined,
              { scroll: false },
            );
          } else {
            Router.push(
              {
                pathname: `/step_2`,
              },
              undefined,
              { scroll: false },
            );
          }
        }
      });
  }

  async seaResolveBooking() {
    if (this.isAstoria) {
      await this.astoriaLinkForBooking();
    } else {
      await this.temporaryLinkForBooking();
    }
  }

  get _astoriaLinkForBooking() {
    return `/api/v1/orders/tmp_order/?cabin_no=${this?.cabin}&tarif=${
      this.activeCabin?.category_info?.category
    }&price=${
      this?.price !== 0 ? this?.price : this?.tariff?.price
    }&spaciousness=${this.spaciousness}&cruiseId=${
      isClient && Router?.query?.id
    }&nameTarrif=Взрослых:${this.adults}Подростоков:${this.teenager}Детей:${
      this.child
    }&fio=${this.fio}&phone=${this.phone}&priceCur=${this.tariff.valueCurrent}`;
  }

  // &adults_num=${this.adults}&teenage_num=${this.teenager}&child_num=${
  //   this.child
  // }
  setShowTooltip(show) {
    this.isShowTooltip = show;
  }

  setDataTooltip(data) {
    this.dataTooltip = data;
  }

  tooltipSeaInfo() {
    if (isClient || this.activeTab === 'scheme') {
      const scheme = document.querySelector('.scheme');
      scheme?.addEventListener('mouseover', event => {
        const translitActiveCabin = translit(
          event.target.innerHTML,
        ).toLowerCase();

        const clickToCabin =
          event.target?.className?.animVal?.includes('CautaClick');
        // TODO: Копипаста
        const data =
          this.astoriaFreeCabins &&
          Object.values(this.astoriaFreeCabins).find(
            x => x.CabinNo?.toString() === translitActiveCabin,
          );

        if (data && clickToCabin) {
          this.setShowTooltip(true);
          this.setDataTooltip({
            x: event.target.getBoundingClientRect().left - 58,
            y: event.target.getBoundingClientRect().top + 33,
            data: {
              cabinNumber: data?.CabinNo ?? '',
              minPrice: data?.min_passenger_price ?? '',
              totalPlaces: data?.capacity ?? '',
              categoryCabin: data?.CategoryName ?? '',
            },
          });
        }
      });

      scheme?.addEventListener('mouseout', () => {
        this.setShowTooltip(false);
      });
    }
  }

  async getAvailableCabins() {
    const { api } = this;
    this.loadingCabin = true;
    const {
      query: { id, category },
    } = Router;

    try {
      const res = await api.getAvailableCabins(id, this.spaciousness);
      const astoriaCabins = res.data
        ?.map(i => i.category_cabins)
        .map(cab => cab)
        .flat();
      const astoriaCategory = res.data?.map(i => i.category_info);
      const astoriaAllData = res.data?.map(i => i);

      this.astoriaFreeCabins = [...astoriaCabins];
      this.astoriaActiveCategory = [...astoriaCategory];
      this.astoriaAllData = [...astoriaAllData];

      this.loadingCabin = true;
      this.setCabins(res.data);

      const data = res.data?.find(i => i.category_info.id === +category);
      const tarrifInCategoryCabin = data.price;
      const numbersInCategoryCabin = data.category_cabins;
      this.setCabinNumbers(numbersInCategoryCabin);

      this.setInitTarrif(
        tarrifInCategoryCabin[0].tariff,
        tarrifInCategoryCabin[0].value_curr,
        tarrifInCategoryCabin[0].value_rub,
        tarrifInCategoryCabin[0].detailed_price,
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingCabin = false;
    }
  }

  async createOrder() {
    const { api } = this;

    await api.createOrder(this.dataToBooking).then(res => {
      console.log(res.data);
    });
  }

  get cabinsSuite() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Сьют')
      .map(i => i?.CabinNo);
  }

  get cabinsBalcony() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Каюта с балконом')
      .map(i => i?.CabinNo);
  }

  get cabinsWindow() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Каюта с окном')
      .map(i => i?.CabinNo);
  }

  get cabinsObstructedView() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Каюта с окном(вид загорожен)')
      .map(i => i?.CabinNo);
  }

  get cabinsPorthole() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Каюты с иллюминатором')
      .map(i => i?.CabinNo);
  }

  get cabinsWithoutWindow() {
    return this.astoriaFreeCabins
      .filter(i => i?.root_type === 'Каюта без окна')
      .map(i => i?.CabinNo);
  }
  get vacantCabins() {
    return Object.values(this.astoriaFreeCabins).map(i => i?.CabinNo);
  }

  async addToSelection() {
    const { api } = this;

    const desc = `${this.activeCabin.category_info.description}.`;
    await api
      .addToSelection(this.id, desc, JSON.stringify(this.activeCabin))
      .then(() => alert('Добавлено в подбор!'));
  }

  highlightCabins() {
    if (isClient) {
      if (this.activeTab === 'scheme') {
        /*   const list = document.getElementsByClassName('st1');
        let j;
        for (j = 0; j < list.length; ++j) {
          list[j].style.color = '#fff';
        } */

        this.cabinsSuite.forEach(i => {
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsSuite');
        });
        this.cabinsBalcony.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsBalcony'),
        );
        this.cabinsWindow.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsWindow'),
        );
        this.cabinsObstructedView.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsObstructedView'),
        );
        this.cabinsPorthole.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsPorthole'),
        );
        this.cabinsWithoutWindow.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeCabinsWithoutWindow'),
        );
      }
    }
  }

  // Свойство, чтобы определять нужно ли отправлять свойство при открытии модального окна
  // На половине запросов нужно отправлять запросы, чтобы узнать номера кают, а у некоторых вендоров уже приходит category_cabins
  get hasCabinInAvailableCabins() {
    return !!this.activeCabin?.category_cabins?.length ?? false;
  }
  get isMSC() {
    return this.providerId === 100019;
  }

  hydrate(data = {}) {
    this.token = data?.token;
    this.id = data?.idCruiz;
    this.providerId = data?.cruiz?.cruise?.provider_id;
  }
}
