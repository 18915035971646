import { removeDuplicates } from '../utils/removeDuplicates';

export class DiscountStore {
  rawDiscount;

  static normalizeDiscount(value) {
    // делаем из скидок массив, если там объекты
    if (!DiscountStore.isDiscount(value)) return [];

    return Array.isArray(value)
      ? value
      : Object?.values(Object?.values(value[-1]));
  }

  static isDiscount(value) {
    // проверка на наличие содержимового
    return !!value;
  }

  static discountsWithoutDuplicates(value) {
    // очистка массива от дублей
    return removeDuplicates(value);
  }

  static filteredDiscounts(value) {
    // фильтрация массива по процентам
    return value.filter(
      i => i.alias && i.value && i.alias !== 'Раннее бронирование',
    );
  }

  static sortMinToMaxDiscounts(value) {
    // сортировка от минимального процента к максимальному
    return value.map(i => i.value).sort((a, b) => a - b);
  }

  static gluingDiscounts(key, value) {
    // склейка массивов с ключами и значениями по процентам
    return key.map(i => ({
      [i]: value.filter(d => d.value === i),
    }));
  }

  get discounts() {
    // поэтапный вызов методов для обработки скидок
    const getNormalizeDiscount = DiscountStore.normalizeDiscount(
      this.rawDiscount,
    );

    if (getNormalizeDiscount.length === 0) return [];

    const getFilteredDiscounts =
      DiscountStore.filteredDiscounts(getNormalizeDiscount);

    const getSortMinToMaxDiscounts =
      DiscountStore.sortMinToMaxDiscounts(getFilteredDiscounts);

    const getDiscountsWithoutDuplicates =
      DiscountStore.discountsWithoutDuplicates(getSortMinToMaxDiscounts);

    return DiscountStore.gluingDiscounts(
      getDiscountsWithoutDuplicates,
      getFilteredDiscounts,
    );
  }

  get rb() {
    return (
      DiscountStore.isDiscount(this.rawDiscount) &&
      this.rawDiscount[-1] &&
      this.rawDiscount[-1][6657]
    );
  }

  get isDiscounts() {
    return !!this.discounts.length;
  }

  hydrate(data) {
    this.rawDiscount = data.discounts;
  }
}
