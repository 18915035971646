import { oldApi } from '../lib/api';

const resolveTypeApi = isSea => (isSea ? 'Bron_sea_new' : 'Bron_new');
export class BookingApi {
  async sendFirstStepForm({ name, phone, isSubscribed, isSea }) {
    return await oldApi.post(`${resolveTypeApi(isSea)}/formOne`, {
      name,
      phone,
      is_agreement_email_given: isSubscribed,
      is_agreement_sms_given: isSubscribed,
    });
  }

  async createOrder({
    cabContent,
    isSubscribed,
    token,
    isSea,
    fio,
    phone,
    bitrixId,
    orderId,
    user,
    ym_cid,
  }) {
    return await oldApi.post(`${resolveTypeApi(isSea)}/create_order`, {
      cab_content: cabContent,
      is_agreement_email_given: isSubscribed,
      is_agreement_sms_given: isSubscribed,
      token,
      fio,
      phone,
      bitrix_id: bitrixId,
      order_id: orderId,
      user_parent_id: user,
      ym_cid,
    });
  }

  async getOwnerParamsByToken(token) {
    return await oldApi.get(`/ApiOrders/getOwnerParamsByToken/${token}`);
  }

  async getStep2({ orderId, isSea }) {
    return await oldApi.post(`${resolveTypeApi(isSea)}/get_step2`, {
      order_id: orderId,
    });
  }
  async getStep2Sea({ orderId }) {
    return await oldApi.post(`Bron_sea_new/get_step2`, {
      order_id: orderId,
    });
  }

  async sendPassengersData(data, isSea) {
    return await oldApi.post(`${resolveTypeApi(isSea)}/step2`, {
      data,
    });
  }

  async getStep3({ orderId, isSea }) {
    return await oldApi.post(`${resolveTypeApi(isSea)}/get_step3`, {
      order_id: orderId,
    });
  }

  async paymentQR(data) {
    const { order_id, pay_percent, amount, data_type } = data;
    return await oldApi.post(`/Tinkoff_payment/getQr/`, {
      order_id,
      pay_percent,
      amount,
      data_type,
    });
  }

  async paymentCard(data, isSea) {
    const { order_id, cruis_start, pay_percent, ship_prepay_days } = data;
    return await oldApi.post(`${resolveTypeApi(isSea)}/payment_card`, {
      order_id,
      cruis_start,
      pay_percent,
      ship_prepay_days,
    });
  }

  async sbpChecked(value) {
    return await oldApi.post('/Tinkoff_payment/getQrState', {
      id_tinkoff: value,
    });
  }
  async sendInstallment(orderId) {
    return await oldApi.post('Bron_new/installment', {
      order_id: orderId,
    });
  }
  async submitPromoCode(id, promo = '') {
    return await oldApi.post(
      `/ApiPromo/apply_promo?order_id=${id}&promocode=${promo}`,
    );
  }

  async operatorReserve(cabContent) {
    return await oldApi.post(`ApiOrders/operator_reserve`, {
      cab_content: cabContent,
    });
  }
}
