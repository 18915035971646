import React from 'react';
import cc from 'classcat';
import styles from './text.module.sass';

/**
 * Компоненты для текста и ссылок
 */
export const Text = React.forwardRef(
  (
    {
      className,
      component = 'div',
      children,
      size,
      bottomGap,
      topGap,
      weight,
      decoration,
      transform,
      style,
      color,
      align,
      variant = 'text',
      elementRef,
      priceLine,
      colorText,
      opacity,
      // disabled,
      ...others
    },
    ref,
  ) =>
    React.createElement(
      component,
      {
        className: cc([
          styles.text,
          styles[variant],
          styles[size],
          styles[color],
          styles[colorText],
          { [styles.priceLine]: priceLine, [className]: className },
        ]),
        style: {
          fontWeight: weight,
          textTransform: transform,
          textAlign: align,
          textDecoration: decoration,
          marginBottom: `${bottomGap}px`,
          marginTop: `${topGap}px`,
          opacity,
          ...style,
        },
        ref: ref ?? elementRef,
        ...others,
      },
      children,
    ),
);

export function Anchor({ component = 'a', variant, ...others }) {
  return <Text component={component} variant={variant || 'link'} {...others} />;
}

Text.defaultProps = {
  size: 'md',
  decoration: null,
  transform: null,
  weight: null,
  style: null,
  align: 'none',
  component: 'div',
  variant: 'text',
  color: null,
  topGap: null,
  bottomGap: null,
  opacity: '1',
};
