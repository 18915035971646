export const makeAliasPlaces = (places, extraPlaces) => {
  let alias;

  if (places === 'one' || places === 1) {
    alias = 'Одноместная';
  } else if (places === 'two' || places === 2) {
    alias = 'Двухместная';
  } else if (places === 'three' || places === 3) {
    alias = 'Трехместная';
  } else if (places === 'four' || places === 4) {
    alias = 'Четырёхместная';
  }

  if (typeof places === 'number') {
    return alias;
  }

  return extraPlaces ? `${alias} с доп. местом` : alias;
};
