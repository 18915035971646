import Router from 'next/router';


export const removeQueryParam = (param) => {
  const { pathname, query } = Router;
  const params = new URLSearchParams(query);
  params.delete(param);
  Router.replace(
      { pathname, query: params.toString() },
      undefined, 
      { shallow: true }
  );
};