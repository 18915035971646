import React from 'react';
import { Field } from 'formik';
import cc from 'classcat';
import { useMedia } from 'react-use';
import BaseSelect, { components } from 'react-select';
import styles from './select.module.sass';

/**
 * Селект
 */
export const Select = ({
  options,
  placeholder = '',
  compact,
  isMulti,
  widthControl,
  heightControl,
  name,
  withoutFormik,
  onChange,
  value,
  id,
  idSelect,
  actionOnChange,
  defaultValue,
  customValue,
  isDisabled,
  readOnly,
  ...oth
}) => {
  const fullStyles = {
    option: provided => ({
      ...provided,
      cursor: 'pointer',
      background: '#fff',
      color: '#000',
      paddingLeft: '16px',
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(24, 70, 143, 0.05), rgba(24, 70, 143, 0.05)), #FFFFFF',
        boxShadow: 'none',
        color: '#000',
      },
    }),
    control: (provided, state) => {
      const { isFocused } = state;
      let border;
      if (isFocused) {
        border = `1px solid ${
          readOnly ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.5)'
        }`;
      } else {
        border = `1px solid rgba(0, 0, 0, 0.15)`;
      }

      return {
        ...provided,
        display: 'flex',
        minWidth: '100%',
        height: heightControl,
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        background: state.isDisabled ? '#f2f2f2' : '#fff',
        borderRadius: '4px',
        color: state.isDisabled && 'rgba(3, 18, 40, 0.5)',
        border,
        cursor: readOnly ? 'default' : 'pointer',
        boxShadow: 'none',
        padding: ' 8px 16px 8px 8px;',
        '&:hover': {
          borderColor: readOnly ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.5)',
          boxShadow: 'none',
        },
        '&:focus': {
          borderColor: readOnly ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.5)',
          boxShadow: 'none',
        },
      };
    },
    container: provided => ({
      ...provided,
      minWidth: '100%',
      '&:hover': {
        borderColor: readOnly ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.5)',
        boxShadow: 'none',
      },
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
      marginBottom: '8px',
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '-3px',
      border: '1px solid rgba(0, 0, 0, 0.5)',
      borderTop: '1px solid transparent',
      '-webkit-overflow-scrolling': 'touch',
      overscrollBehavior: 'contain',
      borderTopRightRadius: state.isFocused ? '4px' : '0px',
      borderTopLeftRadius: state.isFocused ? '4px' : '0px',
      boxShadow: 'none',
    }),
    menuList: provided => ({
      ...provided,
      '&:hover': {
        background: 'none',
      },
      padding: '0px',
      overscrollBehavior: 'contain',
    }),
    placeholder: () => ({
      display: 'flex',
      position: 'static',
      transform: 'translateY(7%)',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '8px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'start',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0px',
      display: readOnly ? 'none' : 'flex',
      alignItems: 'center',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValueRemove: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: '14px',
      padding: '0px',
      background: '#fff',
      margin: '0',
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '14px',
      padding: '0px',
      paddingLeft: '0px',
      background: '#fff',
    }),
    ValueContainer: provided => ({
      ...provided,
      overflow: 'hidden',
      padding: '8px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      position: 'static',
      overflow: 'hidden',
      transform: 'translateY(40%)',
      color: state.isDisabled && 'rgba(3, 18, 40, 0.5)',
    }),
  };

  const compactStyles = {
    option: provided => ({
      ...provided,
      cursor: 'pointer',
      fontWeight: 'bold',
      background: '#fff',
      color: '#000',
      paddingLeft: '16px',
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(24, 70, 143, 0.05), rgba(24, 70, 143, 0.05)), #FFFFFF',
        boxShadow: 'none',
        color: '#000',
      },
    }),
    control: (provided, state) => {
      const { isFocused, hasValue } = state;
      let border;
      if (isFocused) {
        border = '1px solid rgba(0, 0, 0, 0.5)';
      } else if (hasValue) {
        border = '2px solid rgba(0, 0, 0, 0.5)';
      } else {
        border = '1px solid rgba(0, 0, 0, 0.15)';
      }

      return {
        ...provided,
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        borderRadius: '4px',
        border,
        width: widthControl,
        height: heightControl,
        cursor: 'pointer',
        boxShadow: 'none',
        padding: '2px 16px',
        '&:hover': {
          borderColor: ' rgba(0, 0, 0, 0.5)',
          boxShadow: 'none',
        },
      };
    },
    container: provided => ({
      ...provided,
      width: 'auto',
      '&:hover': {
        borderColor: ' rgba(0, 0, 0, 0.5)',
        boxShadow: 'none',
      },
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
      marginBottom: '8px',
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '-3px',
      '-webkit-overflow-scrolling': 'touch',
      overscrollBehavior: 'contain',
      border: '1px solid rgba(0, 0, 0, 0.5)',
      borderTop: '1px solid transparent',
      borderTopRightRadius: state.isFocused ? '4px' : '0px',
      borderTopLeftRadius: state.isFocused ? '4px' : '0px',
      boxShadow: 'none',
      width: widthControl,
    }),
    menuList: provided => ({
      ...provided,
      '&:hover': {
        background: 'none',
      },
      padding: '0px',
      '-webkit-overflow-scrolling': 'touch',
      overscrollBehavior: 'contain',
    }),
    placeholder: () => ({
      display: 'flex',
      alignItems: 'center',
      position: 'static',
      transform: 'translateY(7%)',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0px',
      fontWeight: 'bold',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0px',
      display: 'flex',
      alignItems: 'center',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValueRemove: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: '14px',
      padding: '0px',
      background: '#fff',
      margin: '0',
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '14px',
      padding: '0px',
      paddingLeft: '0px',
      background: '#fff',
    }),
    singleValue: () => ({
      position: 'static',
      transform: 'translateY(7%)',
    }),
  };

  const MultiValue = ({ data, children, ...props }) => {
    const { selectProps } = props;
    const indexInValue = selectProps.value.findIndex(x => x === data);
    const valueLength = selectProps.value.length - 1;

    if (indexInValue < 1) {
      return (
        <span className={styles.selectorShape}>
          {indexInValue !== 0 ? ',' : null} {children}
        </span>
      );
    }

    if (indexInValue === valueLength) {
      return <span className={styles.plus}>+{valueLength}</span>;
    }

    return null;
  };

  const Option = props => {
    const { label, isSelected } = props;
    return isMulti ? (
      <components.Option classNamePrefix="react-select" {...props}>
        <div className={styles.selectDropdown}>
          <div className={styles.selectDropdownLabel}>{label}</div>
          <div className={styles.selectDropdownPick}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11.2301L11.7059 15.3327L17 8.66602"
                stroke={isSelected ? '#0274e6' : '#e5e5e5'}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </components.Option>
    ) : (
      <components.Option classNamePrefix="react-select" {...props} />
    );
  };

  const CaretDownIcon = () => (
    <div
      className={cc([
        [styles.selectCaretDownIcon],
        {
          [styles.selectCaretDownIconDisabled]: isDisabled,
          [styles.selectCaretDownIconReadOnly]: readOnly,
        },
      ])}
    />
  );

  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );

  const ValueContainer = props => {
    const {
      selectProps: { menuIsOpen },
      hasValue,
      children,
    } = props;

    const shouldFloatLabel = menuIsOpen || hasValue;
    return compact ? (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    ) : (
      <components.ValueContainer {...props}>
        {
          <span
            className={cc([
              [styles.selectPlaceholder],
              {
                [styles.selectPlaceholderActive]: shouldFloatLabel,
              },
            ])}
          >
            {placeholder}
          </span>
        }
        {customValue ? (
          <>
            <div className={styles.itemWrapper}>{children}</div>
            <div className={styles.valueWrapper}>{customValue}</div>
          </>
        ) : (
          <>{children}</>
        )}
      </components.ValueContainer>
    );
  };

  const singleComponents = {
    DropdownIndicator,
    Option,
    MultiValue,
    ValueContainer,
  };

  const multiComponents = {
    DropdownIndicator,
    Option,
    MultiValue,
  };
  const isWide = useMedia('(min-width: 767px)');
  return (
    <Field id={name} name={name}>
      {({ field, form }) => {
        const formikOnChange = option => {
          if (actionOnChange) {
            actionOnChange({
              name: field.name,
              value: option.value,
              options,
            });
          }
          form.setFieldValue(
            field.name,
            isMulti ? option.map(item => item.value) : option.value,
          );
        };
        // еще один иф, только
        const getValue = () => {
          if (options) {
            return isMulti
              ? options.filter(option => field.value.indexOf(option.value) >= 0)
              : options.find(option => {
                  if (options.find(e => e.value === field.value)) {
                    return option.value === field.value;
                  }
                  return options[0];
                });
          }
          return isMulti ? [] : '';
        };
        return (
          <BaseSelect
            options={options}
            name={field.name}
            isMulti={isMulti}
            components={isMulti ? multiComponents : singleComponents}
            placeholder={compact && placeholder}
            isSearchable={false}
            hideSelectedOptions={false}
            noOptionsMessage={() => null}
            id={idSelect || 'some-react-select'}
            instanceId={idSelect || 'some-react-select'}
            isClearValue={() => null}
            isClearable={false}
            openMenuOnClick={!readOnly}
            это
            styles={compact ? compactStyles : fullStyles}
            value={getValue()}
            onChange={onChange || formikOnChange}
            closeMenuOnSelect={!isWide || !isMulti}
            isDisabled={isDisabled}
            {...oth}
            classNamePrefix="react-select"
          />
        );
      }}
    </Field>
  );
};

Select.defaultProps = {
  compact: null,
  controlWidth: null,
  heightControl: null,
  isMulti: false,
  isDisabled: false,
  readOnly: false,
};
