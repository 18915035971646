import { AuthStore } from './AuthStore';
import { CruizStore } from './CruizStore';
import { SearchStore } from './SearchStore';
import { ShipsSearchStore } from './ShipsSearchStore';
import { BookingStore } from './BookingStore';
import { ShipsStore } from './ShipsStore';
import { AuthApi } from '../api/AuthApi';
import { CruizApi } from '../api/CruizApi';
import { SearchApi } from '../api/SearchApi';
import { ShipsSearchApi } from '../api/ShipsSearchApi';
import { SeaCruizApi } from '../api/SeaCruizApi';
import { SearchShipApi } from '../api/SearchShipApi';
import { CartStore } from './CartStore';
import { SeaCruiseStore } from './SeaCruiseStore';
import { MyCabinetStore } from './MyCabinetStore';
import { MyCabinetApi } from '../api/MyCabinetApi';
import { BookingApi } from '../api/BookingApi';
import { PromoCodeApi } from '../api/PromoCodeApi';
import { DeparturesStore } from './DeparturesStore';
import { DeparturesApi } from '../api/DeparturesApi';
import { AdminStore } from './AdminStore';
import { AdminApi } from '../api/AdminApi';
import { SendReviewsStore } from './SendReviewsStore';
import { DiscountStore } from './DiscountStore';
import { PromoCode } from './PromoCode';
import { MarketingApi } from '../api/MarketingApi';
import { MarketingStore } from './MarketingStore';
import { BlogStore } from './BlogStore';
import { BlogApi } from '../api/BlogApi';

export class RootStore {
  authStore;
  cruizStore;
  seaCruiseStore;
  searchStore;
  searchShips;
  myCabinetStore;
  bookingStore;
  departuresStore;
  adminstore;
  discountStore;
  promoCode;
  marketingStore;
  blogStore;

  constructor() {
    this.authStore = new AuthStore(this, new AuthApi());
    this.cruizStore = new CruizStore(this, new CruizApi(), new CartStore(this));
    this.seaCruiseStore = new SeaCruiseStore(this, new SeaCruizApi());
    this.searchStore = new SearchStore(this, new SearchApi());
    this.shipsSearchStore = new ShipsSearchStore(this, new ShipsSearchApi());
    this.searchShips = new ShipsStore(this, new SearchShipApi());
    this.myCabinetStore = new MyCabinetStore(this, new MyCabinetApi());
    this.bookingStore = new BookingStore(this, new BookingApi());
    this.departuresStore = new DeparturesStore(this, new DeparturesApi());
    this.adminStore = new AdminStore(this, new AdminApi());
    this.sendReviewsStore = new SendReviewsStore();
    this.discountStore = new DiscountStore();
    this.promoCode = new PromoCode(this, new PromoCodeApi());
    this.marketingStore = new MarketingStore(this, new MarketingApi());
    this.blogStore = new BlogStore(this, new BlogApi());
  }

  hydrate(data) {
    if (data.user) {
      this.authStore.hydrate(data.user);
    }

    if (data.cruiz || data.places) {
      this.cruizStore.hydrate(data);
    }

    if (data.searchStore) {
      this.searchStore.hydrate(data.searchStore);
    }

    if (data.popdir) {
      this.searchStore.hydrate(data.popdir);
    }

    if (data) {
      this.seaCruiseStore.hydrate(data);
    }
    if (data) {
      this.myCabinetStore.hydrate(data);
    }

    if (data.departuresData) {
      this.departuresStore.hydrate(data);
    }


    if (data) {
      this.shipsSearchStore.hydrate(data);
    }

    if (data.orders) {
      this.adminStore.hydrate(data);
    }
    if (data) {
      this.discountStore.hydrate(data);
    }

    if (data) {
      this.bookingStore.hydrate(data);
    }

    if (data) {
      this.marketingStore.hydrate(data);
    }

    if (data.blog) {
      this.blogStore.hydrate({posts: data.blog});
    }

    if (data.article) {
      this.blogStore.hydrate({post: data.article});
    }
  }
}
