export const isJSON = (str) => {
  if (typeof str !== 'string') {
      return false;
  }

  if (str.trim() === '' || str.length < 2) {
      return false;
  }

  try {
      const obj = JSON.parse(str);
      return (typeof obj === 'object' && obj !== null) || Array.isArray(obj);
  } catch (e) {
      return false;
  }
}
