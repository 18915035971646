import React from 'react';
import { NextSeo } from 'next-seo';
import { Page503 } from '../ErrorPage/503';
import { RootStoreProvider } from '../../providers/RootStoreProvider';
import { Layout } from '../Layout';
import { SearchStoreProvider } from '../../providers/SearchStoreProvider';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <SearchStoreProvider>
          <RootStoreProvider>
            <NextSeo title={'Ошибка 500: страница не доступна'} />
            <Layout
              isMobile={this.props.isMobile}
              isTablet={this.props.isTablet}
              isOutsPage
            >
              <Page503 />
            </Layout>
          </RootStoreProvider>
        </SearchStoreProvider>
      );
    }

    return this.props.children;
  }
}
