// https://codesandbox.io/s/react-calendar-forked-02sgn?file=/src/App.js
// https://github.com/wojtekmaj/react-calendar
import React, { memo, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { useFormikContext } from 'formik';
import cc from 'classcat';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import { DropDown } from '../DropDown';
import styles from './datePickerModal.module.sass';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

dayjs.extend(customParseFormat);
dayjs.locale('ru');

export const DatePickerModal = memo(
  ({
    value = '',
    id,
    label,
    minDate,
    disabledDate = [],
    last,
    loading,
    firstLoading,
    styleView,
    isBodyRight = false,
    actionOnChange,
    url,
    ...props
  }) => {
    const { setFieldValue } = useFormikContext();
    const isLandingView = styleView === 'landing';
    const isAdminView = styleView === 'admin';

    const perfectDate = rawtDate => {
      const formatRule = 'DD MMM YYYY';
      const [start, end] = rawtDate;

      const reformat = (val, rule = formatRule) =>
        val ? dayjs(val).format(rule) : '';

      const datesEquality = dayjs(start).date() === dayjs(end).date();

      if (start && end && !datesEquality) {
        const secondDate = reformat(end);

        if (dayjs(start).month() === dayjs(end).month()) {
          const firstDay = reformat(start, 'DD');
          return `${firstDay} - ${secondDate}`;
        }

        const firstFullDay = reformat(start, 'DD.MM.YYYY');
        const secondFullDay = reformat(end, 'DD.MM.YYYY');
        return secondDate ? `${firstFullDay} - ${secondFullDay}` : '';
      }

      return reformat(start);
    };

    const perfectValue = useMemo(() => perfectDate(value), [value]);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const startMonth = () => {
      if (!value) {
        return minDate && new Date(minDate);
      }
      return value[0];
    };
    const [activeDate, setActiveDate] = useState(startMonth());

    useEffect(() => {
      setActiveDate(startMonth());
    }, [minDate, value]);

    const btn = (
      <span
        className={cc([
          url === 'black_friday' ? styles.arrowBf : styles.arrow,
          styles[styleView],
        ])}
      >
        {isAdminView && (
          <Icon
            icon={
              <IconSVG name={'common/calendar-arrow'} width={9} height={15} />
            }
            title=""
          />
        )}
      </span>
    );

    return (
      <div>
        <DropDown
          url={url}
          firstLoading={firstLoading}
          value={perfectValue}
          id={id}
          isBodyRight={isBodyRight}
          fluid={false}
          clearValue={''}
          label={label}
          last={last}
          view={styleView}
          trigger={isAdminView ? 'picker' : 'input'}
          onClick={() => setModalIsOpen(!modalIsOpen)}
          isModalDrop
          {...props}
        />
        <Modal
          url={url}
          isOpen={modalIsOpen}
          onOpen={setModalIsOpen}
          fullScreen
          title="Даты отправления"
        >
          <div className={styles.datePicker}>
            <Calendar
              onChange={date => {
                setFieldValue(id, date);
                if (actionOnChange) {
                  actionOnChange();
                }
              }}
              value={value}
              activeStartDate={activeDate}
              onActiveStartDateChange={({
                action,
                activeStartDate: date,
                view,
              }) => {
                if (view === 'month') {
                  setActiveDate(
                    new Date(
                      date.setMonth(
                        action === 'prev' ? date.getMonth() : date.getMonth(),
                      ),
                    ),
                  );
                }
              }}
              className={cc([
                'search-calendar',
                {
                  'landing-calendar': isLandingView,
                  'admin-calendar': isAdminView,
                  'promotion-calendar': url === 'black_friday',
                },
              ])}
              minDate={new Date(minDate)}
              formatMonth={date => dayjs(date).format('DD MMM YYYY')}
              tileDisabled={({ date, view }) =>
                (view === 'month' &&
                  disabledDate.some(disabled =>
                    dayjs(date).isSame(dayjs(disabled), 'day'),
                  )) ||
                (loading &&
                  disabledDate.some(
                    disabled => dayjs(date).year() === dayjs(disabled).year(),
                  ))
              }
              showDoubleView
              selectRange
              allowPartialRange
              minDetail="month"
              maxDetail="month"
              defaultView="month"
              prevLabel={btn}
              nextLabel={btn}
              locale="ru-RU"
              navigationLabel={({ date }) => dayjs(date).format('MMMM YYYY')}
            />
          </div>
          {value && (
            <div className={styles.buttonFooter}>
              <Button
                onClick={() => setModalIsOpen(!modalIsOpen)}
                view="buttonModalData"
                background={url === 'black_friday' ? 'primaryBf' : 'primary'}
              >
                Применить
              </Button>
            </div>
          )}
        </Modal>
      </div>
    );
  },
);
