import { enableStaticRendering } from 'mobx-react-lite';
import React, { createContext, useContext } from 'react';
import { RootStore } from '../stores/RootStore';

enableStaticRendering(typeof window === 'undefined');

let store;
const StoreContext = createContext();

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

export function useCruizStore() {
  return useRootStore().cruizStore;
}

export function usePromoCodeStore() {
  return useRootStore().promoCode;
}

export function useAuthStore() {
  return useRootStore().authStore;
}

export function useSearchStore() {
  return useRootStore().searchStore;
}

export function useShipSearchStore() {
  return useRootStore().searchShips;
}

export function useShipsSearchStore() {
  return useRootStore().shipsSearchStore;
}

export function useSeaCruiseStore() {
  return useRootStore().seaCruiseStore;
}

export function useMyCabinetStore() {
  return useRootStore().myCabinetStore;
}

export function useFavorites() {
  return useCruizStore().favoriteStore;
}
export function useReviewsStore() {
  return useRootStore().sendReviewsStore;
}

export function useBookingStore() {
  return useRootStore().bookingStore;
}

export function useMarketingStore() {
  return useRootStore().marketingStore;
}
export function useDeparturesStore() {
  return useRootStore().departuresStore;
}

export function useAdminStore() {
  return useRootStore().adminStore;
}
export function useDiscountStore() {
  return useRootStore().discountStore;
}

export function useBlogStore() {
  return useRootStore().blogStore;
}

function initializeStore(initialData) {
  const _store = store ?? new RootStore();

  if (initialData) {
    _store.hydrate(initialData);
  }

  if (typeof window === 'undefined') return _store;

  if (!store) store = _store;

  return _store;
}

export function RootStoreProvider({ children, hydrationData }) {
  const initStore = initializeStore(hydrationData);

  return (
    <StoreContext.Provider value={initStore}>{children}</StoreContext.Provider>
  );
}
