import React from 'react';
import cc from 'classcat';
import styles from './content.module.sass';

export const Content = ({ children, isBlog, showTopContent, urlBF }) => (
  <div className={!showTopContent ? styles.wrapper : styles.wrapperBanner}>
    <div className="">
      <div
        className={cc([
          [styles.innerWrapper],
          {
            [styles.innerWrapperBlog]: isBlog,
            [styles.innerWrapperBlackFriday]: urlBF === 'black_friday',
          },
        ])}
      >
        {children}
      </div>
    </div>
  </div>
);
