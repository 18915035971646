import React from 'react';
import { Field } from 'formik';
import { Else, If, Then } from 'react-if';
import cc from 'classcat';
import styles from './checkbox.module.sass';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const Checkbox = ({
  name,
  disabled,
  size,
  text,
  children,
  value,
  rawCheckbox,
  view,
  url,
  ...props
}) => {
  const Component = rawCheckbox ? 'input' : Field;
  return (
    <label className={styles.label}>
      <Component
        type="checkbox"
        name={name}
        className={styles.real}
        disabled={disabled}
        value={value}
        hidden
        {...props}
      />
      <If condition={!disabled}>
        <Then>
          <If condition={view === 'compact'}>
            <Then>
              {/* Компактный чекбокс */}
              <span className={cc([styles.invis, styles[size]])}>
                <span className={styles.markCompact} />
                <span
                  className={
                    url === 'black_friday' ? styles.markBf : styles.mark
                  }
                />
              </span>
            </Then>
            <Else>
              {/* Обычный чекбокс */}
              <span className={cc([styles.fake, styles[size], styles[view]])}>
                <span
                  className={
                    url === 'black_friday' ? styles.markBf : styles.mark
                  }
                />
              </span>
            </Else>
          </If>
        </Then>
        <Else>
          {/* Лоадер чекбокса */}
          {/* <img className={styles.loading} src={loader} alt="loading" /> */}
          <div className={styles.loading}>
            <Icon
              icon={
                <IconSVG
                  name={
                    url === 'black_friday'
                      ? 'common/orange-loader-s'
                      : 'common/loader-s'
                  }
                  width={32}
                  height={32}
                />
              }
              width={32}
              height={32}
              title="Loading"
            />
          </div>
        </Else>
      </If>
      <span className={cc([styles.text, styles[text]])}>{children}</span>
    </label>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  size: 'md',
  text: 'normal',
  view: 'main',
};
