import { oldApi } from '../lib/api';

export class CruizApi {
  async addToSelection(id, description, data) {
    return await oldApi.post(`/Podbor/add/${id}`, {
      cab_desc: description,
      cabins_data: data,
    });
  }

  async sendCabinFacilities(params) {
    return oldApi.post(
      `/Contactforms/request_details_cruis/${params.cruizId}`,
      {
        phone: params.phone,
        is_sea: params.isSea,
      },
    );
  }

  async sendCabInfo({ cabContent, fio, phone, bitrixId, user_parent_id }) {
    return await oldApi.post('Bron_new/step1', {
      cab_content: cabContent,
      fio,
      phone,
      bitrix_id: bitrixId,
      user_parent_id,
    });
  }
}
