import React from 'react';
import styles from './chooseyear.module.sass';
import { Select } from '../../../Select';
import { declOfNum } from '../../../../utils/declOfNum';
import { YaAndGaSearchLine } from '../../../../lib/YaAndGaHelper';

export const ChooseYear = ({ id, text, actionOnChildren }) => {
  const options = new Array(17).fill(0).map((_, i) => ({
    label: `${i + 1} ${declOfNum(i + 1, ['год', 'года', 'лет'])}`,
    value: i + 1,
  }));

  return (
    <div className={styles.wrapper}>
      <p>{text}</p>
      <Select
        placeholder="Выберите год рождения"
        name={id}
        options={options}
        widthControl={120}
        compact
        actionOnChange={e => {
          if (actionOnChildren) {
            actionOnChildren();
          }
          YaAndGaSearchLine(`selected_${e.value}yo_field_childs`);
        }}
      />
    </div>
  );
};
