import { isClient } from '../utils/isClient';

export const yaHelper = (section, value) => {
  if (isClient) {
    window.ym(35441960, 'params', {
      defer: true,
      params: { user: { [section]: value } },
    });
  }
};
