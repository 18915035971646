import React from 'react';
import cc from 'classcat';
import styles from './searchinput.module.sass';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const SearchInput = ({ placeholder, size, ...props }) => (
  <label className={styles.label} {...props}>
    <div className={styles.icon}>
      <Icon icon={<IconSVG name={'common/search'} width={14} height={14} />} />
    </div>

    <input
      className={cc({ [styles.input]: true, [styles[size]]: true })}
      placeholder={placeholder}
    />
  </label>
);

SearchInput.defaultProps = {
  placeholder: '',
  size: 'sm',
};
