import { oldApi } from '../lib/api';

export class BlogApi {
  async fetchByBlog(params) {
    return await oldApi.get(`/ApiBlog/get_posts`, {params});
  }

  async fetchByPost(link) {
    return await oldApi.get(`/ApiBlog/get_post/${link}`);
  }
}
