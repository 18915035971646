import { makeAutoObservable, runInAction } from 'mobx';
import memoizeOne from 'memoize-one';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import Router from 'next/router';

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);

const toArray = array =>
  Array.isArray(array) ? array : Object.values(array || []);

const prepareDates = dates => {
  const array = dates;

  if (array?.length === 1) {
    array.push(array[0]);
  }
  return Array.isArray(array)
    ? array.map(i => dayjs(i).format('YYYY-MM-DD')).join(',')
    : '';
};

export class ShipsStore {
  api;
  amount;
  root;
  paramsFilters;
  departureCities;
  directions;
  directionsNames;
  days;
  minDate;
  disabledDate;
  passengers;
  returnCruise;
  urlParams;
  filters;
  bottomFilters;
  data;
  extraShips;
  cruises;
  page;
  filterShips;
  filterDiscounts;
  extraCondition;
  isLoading;
  type;
  shipUrl;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.extraCondition = 'new_main';
    this.cruises = [];
    this.filterShips = [];
    this.extraShips = [];
    this.filterDiscounts = [];
    this.page = 1;
    this.paramsFilters = {
      search_page: 1,
      filter_directions: JSON.stringify([]),
      filter_ships: JSON.stringify({}),
      filter_dates: JSON.stringify({}),
      filter_length: JSON.stringify({}),
      filter_capacity: JSON.stringify({}),
      sort_mode: 'kr_date_start',
      sort_direction: 'ASC',
      sort_changed: 0,
      curSelecting: 'ships_filter',
      extraCondition: 'new_main',
      type: '2022',
      return_cruise: 0,
      filt_month_year: 0,
    };
    this.amount = 0;
    this.shipUrl = "";
    makeAutoObservable(this);
  }

  async setPage() {
    this.page += 1;
    this.paramsFilters.search_page = this.page;
    await this.getList();
  }

  toArrayFromObjOfObj(obj = {}) {
    return Object.values(obj || [])
      .map(item => item.city_name)
      .filter(Boolean);
  }

  toObjFromArray(arr = []) {
    return arr && JSON.stringify(arr.reduce((a, v) => ({ ...a, [v]: v }), {}));
  }

  getDirectionById(id = [], dir = []) {
    return toArray(dir)
      .filter(idx => id.includes(idx.city_name))
      .map(item => item.cities)
      .flat()
      .map(item => item.city_name);
  }

  getRangeDates(startDate = '', endDate = '') {
    const days = dayjs(endDate).diff(dayjs(startDate), 'day');
    return (
      days &&
      [...Array(days + 1).keys()].map(i =>
        dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'),
      )
    );
  }

  getFormatDate(date) {
    if (date) {
      if (
        !date[1] ||
        dayjs(date[0]).format('YYYY-MM-DD') === dayjs(date[1]).format('YYYY-MM-DD')
      ) {
        return [dayjs(date[0]).format('YYYY-MM-DD')];
      }
      return this.getRangeDates(date[0], date[1]);
    }
    return [];
  }

  setFilters(filters) {
    this.clearFilters();
    this.filters = filters;
    this.paramsToUrl();
  }

  convertFiltersToUrl(filters) {
    return {
      start_cities: filters?.departure.join(',') ?? '',
      visit_cities: filters?.direction.join(',') ?? '',
      // sort_direction: this.bottomFilters?.sort.sort_direction ?? '',
      // sort_mode: this.bottomFilters?.sort.sort_mode ?? '',
      cruise_length: filters?.duration.join(',') ?? '',
      cruise_capacity: filters?.passengers?.adults ?? '',
      children_capacity: filters?.passengers?.children ?? '',
      years_capacity: filters?.passengers?.years.join(',') ?? '',
      return_cruise: filters?.goBack ? '1' : '0',
      type: filters?.type !== filters.type ? filters?.type : '',
      cruise_dates: prepareDates(filters?.dates),
    };
  }

  removeBlankProp(obj) {
    return Object.entries(obj)
      .filter(([, v]) => v !== '')
      .filter(([, v]) => v !== 0)
      .filter(([, v]) => v !== '0')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  formatDates(dates = '') {
    const formatter = memoizeOne(d =>
      Object.values(JSON.parse(d)).map(i => dayjs(i)),
    );
    return formatter(dates);
  }

  setParamsFilters() {
    const { duration, dates } = this.filters;
    // this.extraCondition
    this.paramsFilters.extraCondition = this.extraCondition || 'new_main';
    this.paramsFilters.sort_mode =
      this.bottomFilters?.sort?.sort_mode ?? 'kr_date_start';

    this.paramsFilters.sort_direction =
      this.bottomFilters?.sort?.sort_direction ?? 'ASC';

    this.paramsFilters.filter_length = this.toObjFromArray(duration);

    this.paramsFilters.filter_dates = prepareDates(dates);
  }

  setBottomFilters(filters) {
    this.clearFilters();
    this.bottomFilters = filters;
    this.paramsToUrl();
  }

  async paramsToUrl() {
    const { push, pathname, query } = Router;

    await push(
      {
        pathname,
        query: this.removeBlankProp({ ...query, ...this.params }),
      },
      undefined,
      { scroll: false, shallow: true },
    );
  }

  get maxDate() {
    if (!this.disabledDate || this.disabledDate.length === 0) {
      return null;
    }
    return this.disabledDate.reduce((max, currentDate) => 
      dayjs(max).isAfter(dayjs(currentDate)) ? max : currentDate
    );
  }

  clearFilters() {
    this.cruises = [];
    this.data = [];
    this.page = 1;
    this.paramsFilters.search_page = 1;
  }

  updateFilters(data) {
    const { filt_length, filt_dates } = data;

    this.data = Object.values(data?.cruises?.cruises_data || []) ?? [];

    this.cruises = [...this.cruises, ...this.data];

    this.days = filt_length || {};

    this.minDate = filt_dates?.min_date ?? '';

    // this.disabledDate = filt_dates.disabled_dates
    //   ? this.formatDates(filt_dates.disabled_dates)
    //   : [];

    // Условия для того чтоб пагинация не ломала кол-во
    if (data?.cruises?.amount) {
      this.amount = data.cruises.amount;
    }
  }

  async getList() {
    const { api, filters } = this;

    const methods = {
      [filters.type]: 'getList',
    };

    const currentMethod = methods[filters.type];
    this.isLoading = true;
    try {
      const res = await api[currentMethod](
        this.paramsFilters,
        this.shipUrl,
        filters.type,
      );
      this.setParamsFilters(res.data);
      this.updateFilters(res.data);
      runInAction(() => {
        this.isLoading = true;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
