const getParams = () => window.location.search.slice(1);

const makeUtm = params => {
  const paramsArray = params.split('&').map(i => {
    const array = i.split('=');

    return { key: array[0], value: array[1] };
  });
  return paramsArray;
};

export const getUtm = () => {
  const params = getParams();

  const utm = makeUtm(params);

  return utm;
};

export const getUtmByName = (utms, utmName) => {
  const utm = utms.find(i => i.key === utmName);

  return utm;
};
