import nookies from 'nookies';
import { oldApi, api } from '../lib/api';

export class SeaCruizApi {
  async getAvailableCabins(id, spaciousness) {
    return await oldApi.get(
      `/ApiBooking/available_cabins/${id}/0/${spaciousness}`,
    );
  }

  async addToSelection(id, description, data) {
    return await oldApi.post(`/Podbor/add/${id}`, {
      cab_desc: description,
      cabins_data: data,
    });
  }
  async getInfoCabin(category, id, vendor) {
    return await api.get(
      `/ApiBooking/get_category_cabins/${category}/${id}/${vendor}`,
    );
  }

  async seaRequest(fio, phone, cruisId, categoryId, categoryName) {
    return await oldApi.post(`/ApiOrders/request_cabin`, {
      fio,
      phone,
      cruis_id: cruisId,
      category_id: categoryId,
      category_name: categoryName,
      ym_cid: nookies.get(null)._ym_uid || null,
    });
  }

  async createOrder(data) {
    return await api.post(`/ApiOrders/createOrder`, data);
  }

  async astoriaPrice(id, adults, teenager, child, capacity, category) {
    return await api.post(`/ApiBooking/astoriaCabinPrice/${id}`, {
      adults_num: adults,
      teenage_num: teenager,
      child_num: child,
      total_capacity: capacity,
      cabin_cat: category,
    });
  }

  async sendCabInfo({
    cabinNo,
    tarif,
    price,
    spaciousness,
    cruiseId,
    nameTarrif,
    fio,
    phone,
    bitrixId,
    priceCur,
  }) {
    return await oldApi.post('Bron_sea_new/step1', {
      cabin_no: cabinNo,
      tarif,
      price,
      spaciousness,
      cruiseId,
      nameTarrif,
      fio,
      phone,
      bitrix_id: bitrixId,
      priceCur,
    });
  }
}

// ?adults_num=${adults}&teenage_num=${teenager}&child_num=${child}&total_capacity=${capacity}&cabin_cat=${category}
