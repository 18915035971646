import { makeAutoObservable } from 'mobx';

export class BlogStore {
  api;
  root;
  data;
  loading;
  page;
  media;
  article;

  constructor(root, api) {
    this.root = root;
    this.data = [];
    this.api = api;
    this.page = 1;
    this.media = 'all';
    this.loading = false;
    this.article = null;
    makeAutoObservable(this);
  }

  getOnePost = link => {
    this.loading = true;
    this.api
      .fetchByPost(link)
      .then(({ data }) => {
        this.hydrate({ post: data });
      })
      .finally(() => {
        this.loading = false;
      });
  };

  loadPosts(params) {
    this.loading = true;
    this.media = params.media;
    this.page = 1;
    this.api
      .fetchByBlog(params)
      .then(({ data }) => {
        this.hydrate({
          posts: { ...data, posts_list: [...(data.posts_list ?? [])] },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  loadMorePosts(params) {
    this.page = params.page;
    this.loading = true;
    this.api
      .fetchByBlog(params)
      .then(({ data }) => {
        this.data = {
          ...this.data,
          ...data,
          posts_list: [...this.data.posts_list, ...(data.posts_list ?? [])],
        };
      })
      .finally(() => {
        this.loading = false;
      });
  }

  hydrate(data) {
    this.data = data.posts;
    this.article = data.post;
  }
}
