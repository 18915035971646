import { toast } from 'react-toastify';
import axios from 'axios';
import nookies from 'nookies';
import { formatBytes } from '../utils/formatBytes';

export class SendReviewsStore {
  photo;
  async examSize(values, NotifyMessages, readySize, readySizeCheck) {
    if (values && readySizeCheck) {
      Array.from(values).forEach(file => {
        if (formatBytes(file.size, 2, 'examination')) {
          toast.error(NotifyMessages.Error);
          readySize(false);
        } else {
          toast.success(NotifyMessages.Success);
          readySize(false);
        }
      });
    }
  }
  async sendReview(
    values,
    create,
    NotifyMessages,
    openModal,
    resetForm,
    group,
  ) {
    const formData = new FormData();
    formData.append('raiting_ship', values.raiting_ship);
    formData.append('raiting_company', values.raiting_company);
    formData.append('ship', values.ship);
    formData.append('review', values.review);
    formData.append('user_id', values.user_id || null);
    formData.append('username', values.username ? values.username : 'Гость');
    formData.append(
      'ym_cid',
      !group ? nookies.get(null)._ym_uid || null : null,
    );

    if (values.photo) {
      Array.from(values.photo)
        .slice(0, 8)
        .forEach(file => formData.append('photo[]', file, file.name));
    }

    try {
      create(true);
      await axios.post(
        'https://kruiz.online/api/v1/ApiReviews/sendReview',
        formData,
      );
      toast.success(NotifyMessages.Success);
      openModal(false);
    } catch (e) {
      toast.error(NotifyMessages.Error);
    } finally {
      resetForm();
    }
  }
  async fetchNewReviews(
    setIsGetting,
    setReviews,
    setPage,
    NotifyMessages,
    page,
    reviews,
  ) {
    try {
      setIsGetting(true);
      const { data } = await axios.get(
        `https://kruiz.online/api/v1/ApiReviews/viewReviews/0/${page}`,
      );
      setReviews([...reviews, ...data.reviews]);
      setPage(page + 1);
    } catch (e) {
      toast.error(NotifyMessages.Error);
    } finally {
      setIsGetting(false);
    }
  }
}
