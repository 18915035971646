import { oldApi } from '../lib/api';

export class MarketingApi {
  async sendCertificateForm(
    phone,
    name,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
  ) {
    return oldApi.post('main_rest/certificat_callback', {
      phone,
      name,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_term: utmTerm,
    });
  }
  // Ручка с популярными отправлениями/направлениями
  async getPupulardistantion(isSea) {
    return await oldApi.get(`ApiPopdirNew/getHeaderPopdir/${isSea}`);
  }
}
