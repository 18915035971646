//@ts-nocheck
import { MainResult } from '../MainSearch.store';
import { AuthStore } from '../AuthStore';
import { IHydratedStore, IHydrationData } from '@/typings/types';
import { AuthApi } from '@/api/AuthApi';
import { SearchApi } from '@/api/SearchApi';

export class RootSearch implements IHydratedStore {
  authStore;
  mainResult;

  constructor() {
    this.authStore = new AuthStore(this, new AuthApi());
    this.mainResult = new MainResult(this, new SearchApi());
  }

  hydrate(data: IHydrationData) {
    if (data) {
      this.authStore.hydrate(data);
      this.mainResult.hydrate(data);
    }
  }
}
